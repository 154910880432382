import { Stack, StackItem, Text, TextField } from "@fluentui/react";

import styles from "./Modal.module.css";
import { Fragment, useEffect, useState } from "react";
import { Feedback } from "../Feedback/Feedback";
import { Spinner } from "../Spinner";



interface Props {
  closeModal: (value: boolean) => void;
  isStartOver: boolean;
}

export const ModalPrivacy = ({ closeModal, isStartOver }: Props) => {
  const [isCloseDisabled, setIsCloseDisabled] = useState<boolean>(true);
  const [rating, setRating] = useState<number>(-1);
  const [comment, setComment] = useState<string>("");
  const [showSpinner, SetShowSpinner] = useState<boolean>(false);
  // Creat use state form rating and comment  
  const isCloseButtonDiabled = (value: boolean, rating: number, comment: string) => {
    console.log(rating,"rating")
    if(rating>3){
      setIsCloseDisabled(false);
    }
    else{
      setIsCloseDisabled(value)
    }
    setRating(rating);
    setComment(comment);
  }


  return (
    // <div className={styles.modale}>
    <div className={styles.modalDialog} >
      <div className={styles.modalHeader}>
        <h2>Privacy Notice</h2>
        <p className={styles.privacyText}>No “Restricted Control” information as defined in Global Policy 220, including information subject to U.S. or foreign export laws and regulations and Controlled Unclassified Information (“CUI”), may be stored in this application.</p>
        <p className={styles.privacyText}>As a user of this application you must verify that the documents you upload, access, view, download, or otherwise use in the system do not contain information subject to U.S. or foreign export laws and regulations, Controlled Unclassified Information, or other restricted regulatory or contractual information.</p>
        <p className={styles.privacyText}>For further guidance, please see the <a href="https://www.honeywell.com/us/en/privacy-statement" target="_blank">Privacy policy</a>.</p>
        {/* <a href="#" className={`${styles.btnClose} ${styles.closemodale}`} aria-hidden="true">&times;</a> */}
      </div>
      {/* <div className={styles.modalBody}>
        <Feedback checkFeedbackValidated={isCloseButtonDiabled} />
      </div> */}
      <div className={styles.modalFooter}>

        {showSpinner ? <Spinner /> :
          <Stack horizontal horizontalAlign="space-evenly" style={{ width: "100%" }} >
            <StackItem>
              <button onClick={() => { closeModal(false); SetShowSpinner(!showSpinner) }} className={styles.btn} id="btn_ingresar" >AGREE</button>
            </StackItem>
          </Stack>
        }
      </div>
    </div>
    // </div>
  );
};
