
import styles from "./Spinner.module.css";



export const Spinner = () => {
    return (
        <div className={styles.ldsSpinner}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>

    );
};