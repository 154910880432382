import { systemProperties } from './UrlsAndEnvProperties';

class SystemProperty {

    public static hostName = window.location.hostname;

    static getProperty(propertyName: string) {
        if (SystemProperty.isProd()) {
            return systemProperties[propertyName + '_prod'];
        } else if (SystemProperty.isQA()) {
            return systemProperties[propertyName + '_qa'];
        } else if (SystemProperty.isDev()) {
            return systemProperties[propertyName + '_dev'];
        } else if (SystemProperty.isStage()) {
            return systemProperties[propertyName + '_stage'];
        } else if (SystemProperty.isPerformance()) {
            return systemProperties[propertyName + '_performance'];
        } else if (SystemProperty.isLocal()) {
            return systemProperties[propertyName + '_local'];
        } else {
            return '';
        }
    }

    static isProd() {
        return SystemProperty.hostName.includes('itcopilot.honeywell');
    }

    static isStage() {
        return SystemProperty.hostName.includes('-staging') || SystemProperty.hostName.includes('-stage.hia');
    }

    static isPerformance() {
        return SystemProperty.hostName.includes('-performance');
    }

    private static isDev() {
        return SystemProperty.hostName.includes('.dev.');
    }

    private static isQA() {
        return SystemProperty.hostName.includes('-qa.');
    }

    private static isLocal() {
        return SystemProperty.hostName.includes('local');
    }
}

export default SystemProperty;