import * as React from "react";

import styles from "./SupportingCard.module.css";
import { parseSupportingContentItem } from "../SupportingContent/SupportingContentParser";
import { getCitationFilePath } from "../../api/api";
import { useState } from "react";
import { CheckmarkCircle24Filled, CheckmarkCircle24Regular } from "@fluentui/react-icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CopyButton } from "../CopyButton";
import { Display } from "@fluentui/react-components";
import { observer } from "mobx-react";
import { useAppStore } from "../..";
import DOMPurify from "dompurify";


interface Props {
    supportingContent: string[];
    onCitationClicked: (filePath: string) => void;
}

type ParsedType= {
    title: string;
    content: string;
    targetAudience:string
    docUrl:string
    citationTitle:string
};

let  responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const SupportingCard = observer(({ supportingContent, onCitationClicked }: Props) => {
    const appStore = useAppStore();
    const [readMore, setReadMore] = useState(false);
    const [verifiedList, setVerifiedList] = useState<any>({});
    const [currentContent, setCurrentContent] = useState<ParsedType>({title:"",content:"",targetAudience:"",docUrl:"",citationTitle:""});
    // const [newSupportingContent, setNewSupportingContent]= useState<string[]>(supportingContent);
    const [sanitizedCompleteContent,setSanitizedCompleteContent] = useState<any>("");

    function onVerifyHandle(file: string): void {
       const newObj = {...verifiedList};
       Object.keys(newObj).map((x:any)=>{
        if(x.trim() ===file.trim()){
            newObj[x] = newObj[x]== "verified"? "notVerified": "verified";
        }
       })
        setVerifiedList(newObj);
    }

    const handleReadMore=(parsed:any)=>{
        setCurrentContent(parsed);
        setSanitizedCompleteContent(DOMPurify.sanitize(parsed.content));


        setReadMore(!readMore);
    }

    React.useEffect(() => {
        setVerifiedList(verifiedList);
        appStore.setCitationsFeedback(verifiedList);
    }, [verifiedList])

    React.useMemo(() => {
        const obj = {...verifiedList};
        supportingContent.map((x, i) => {
            const parsed = parseSupportingContentItem(x);
            obj[parsed.title.trim()] = "notVerified";
        });
        setVerifiedList(obj);
        responsive.desktop.items = supportingContent?.length < 3 ? supportingContent?.length : 3;
    }, [supportingContent])

    let titleCount=0;
    return (
       
        <>
            {/* <div className={styles.cardContainer}>
                <span className={styles.citationHeader}>CITATIONS:</span>
            </div> */}
           {readMore == false ? 
           ( 
            <div>
           <Carousel responsive={responsive} showDots renderDotsOutside dotListClass={`${styles.reactMultiCarouselDotList}`}>
                {
                    supportingContent.map((x, i) => {

                    const parsed = parseSupportingContentItem(x);
                    const path = parsed.docUrl;
                    const sanitizedContent = DOMPurify.sanitize(parsed.content);
                    let count =0;
                    for (let j =0; j < i ;j++){
                        if(supportingContent[j].includes(parsed.title)){
                            count++;
                        }

                    }
                    return (
                        <div className={`${styles.contentContainer}`}>
                            {/* <div className={`${styles.contentTitle}`}>
                            
                                <ul className={`${styles.citationTitleFilenameList}`}>
                                    <li>
                                        <a key={i} className={styles.citation} title={parsed.title} onClick={() => onCitationClicked(`${path}:${parsed.title}`)}>
                                         {count >0 ?  `${titleCount}.${count} ${parsed.title}` :   `${++titleCount}. ${parsed.title}`}
                                        </a>
                                    </li>
                                    <li className={`${styles.citationTitle}`}>{parsed.citationTitle}</li>
                                </ul>
                            </div> */}
                            <div style={{position: "relative",minHeight: "150px"}}>
                                <div className={styles.contentText} style={{position:"absolute"}} dangerouslySetInnerHTML={{ __html: sanitizedContent }}>
                                     {/* { parsed.content} */}
                                    {/* {parsed.content.length > 450 / responsive.desktop.items ? (
                                        <>
                                            {parsed.content.substring(0, 450 / responsive.desktop.items) + "... "}{" "}
                                            <a className={styles.readMore} onClick={() => handleReadMore(parsed)}>
                                                Read More
                                            </a>
                                        </>
                                    ) : (
                                        parsed.content
                                    )} */}
                                </div>
                               {parsed.content.length > 150 && <div className={styles.readMoreContent}>
                                    <a className={styles.readMore} onClick={() => handleReadMore(parsed)}>... Read More </a>
                                </div>}
                            </div>
                            <div className={`${styles.footerTile}`}>
                                <div className={styles.leftDiv}>
                                    {/* <CopyButton onClick={() =>navigator.clipboard.writeText(parsed.content) } /> */}
                                    <CopyButton content={parsed.contentCopy} />
                                </div>
                                <div className={styles.rightDiv}>
                                    <button
                                        onClick={() => onVerifyHandle(parsed.title)}
                                        type="button"
                                        className={`ms-Button ms-Button--icon ${styles.root128}`}
                                        data-is-focusable="true"
                                        title="This is proper content"
                                        aria-label="Liked the response"
                                        style={{ color: "black" }}
                                    >
                                        <span className="ms-Button-flexContainer " data-automationid="splitbuttonprimary">
                                            <i data-icon-name="Lightbulb" aria-hidden="true" className="ms-Icon root-105 css-137 ms-Button-icon icon-131">
                                                {verifiedList[parsed.title.trim()] == "verified" ? (
                                                    <CheckmarkCircle24Filled style={{ color: "green" }} />
                                                ) : (
                                                    <CheckmarkCircle24Regular />
                                                )}
                                            </i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                <div>
                                <span className={styles.internalDoc} style={{ color: "black" }}>Referred Doc</span>
                                </div>
                                <div>
                                {parsed.targetAudience.includes("Internal") ? (
                                    <span className={styles.internalDoc} style={{ color: "red" }}>{parsed.targetAudience}</span>
                                ) : (
                                    <span className={styles.internalDoc} style={{ color: "green" }}>{parsed.targetAudience}</span> 
                                )}
                                </div>
                            </div>
                        </div>
                    );

                })}
            </Carousel>
            </div>
            )

            : <div className={`${styles.contentContainer}`}>
                            {/* <div className={`${styles.contentTitle}`}>
                                <a  className={styles.citation} title={currentContent.title} onClick={() => onCitationClicked(`${currentContent.docUrl}:${currentContent.title}`)}>
                                    {`${currentContent.title}`}
                                </a>
                                <p className={`${styles.citationTitle}`}> {`${currentContent.citationTitle}`}</p>
                            </div> */}
                            < div >
                                <div className={styles.contentText} style={{height:"auto"}}  >
                                <>
                                <div dangerouslySetInnerHTML={{ __html: sanitizedCompleteContent }}></div>
                                <a className={styles.readMore} onClick={()=>handleReadMore({title:"",content:"",targetAudience:"",docUrl:""})}>Read less</a></>
                                   
                                </div>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <div className={styles.leftDiv}>
                                    <CopyButton content={currentContent.content} />
                                </div>
                                <div className={styles.rightDiv}>
                                    <button onClick={() => onVerifyHandle(currentContent.title)} type="button" className={`ms-Button ms-Button--icon ${styles.root128}`} data-is-focusable="true" title="This is proper content" aria-label="Liked the response" style={{ color: "black" }}>
                                        <span className="ms-Button-flexContainer " data-automationid="splitbuttonprimary">
                                            <i data-icon-name="Lightbulb" aria-hidden="true" className="ms-Icon root-105 css-137 ms-Button-icon icon-131" >
                                                {(verifiedList[currentContent.title.trim()]== "verified")  ? <CheckmarkCircle24Filled style={{ color: "green" }} /> : <CheckmarkCircle24Regular />}
                                            </i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div>
                                {currentContent.targetAudience.includes("Internal") ? (
                                    <span className={styles.internalDoc} style={{ color: "red" }}>{currentContent.targetAudience}</span>
                                ) : (
                                    <span className={styles.internalDoc} style={{ color: "green" }}>{currentContent.targetAudience}</span> 
                                )}
                            </div>
                        </div>}

        </>
    );
});




