import * as React from "react";
import {
  Label,
  makeStyles,
  Radio,
  RadioGroup,
  tokens,
  useId,
} from "@fluentui/react-components";
import { Stack, StackItem } from "@fluentui/react";
import { useAppStore } from "../..";
import { useEffect } from "react";

const useStyles = makeStyles({
  field: {
    display: "grid",
    gridRowGap: tokens.spacingVerticalS,
  },
});

export const FeedbackRadio = () => {
  const appStore = useAppStore()
  const styles = useStyles();
  const labelId = useId("label");
  const labelId2 = useId("label2");
  const labelId3 = useId("label3");
  const [resolverGroupHelped , setResolverGroupHelped] = React.useState("0");
  const [documentRelevant, setDocumentRelevant] = React.useState("0");
  const [solutionAccurate, setSolutionAccurate] = React.useState("0");
  const handleFeedback=()=>{
    let valueSet={
      resolverGroupHelped:parseInt(resolverGroupHelped),
      documentRelevant:parseInt(documentRelevant),
      solutionAccurate:parseInt(solutionAccurate),
    };
    console.log(valueSet,"valueset")
    appStore.setUsedGenAI(valueSet);
  }
  useEffect(() => {
  handleFeedback();
  }, [resolverGroupHelped,solutionAccurate,documentRelevant])


 
  return (
    <div className={styles.field}>
      <Stack style={{ flexDirection: "row" }}>
        <StackItem>
          <Label style={{ textAlign: "left" ,verticalAlign:"middle"}} id={labelId}>Was the suggested resolver from AI the actual resolvers who fixed the incident?</Label>
        </StackItem>
        </Stack>
        <Stack style={{ flexDirection: "row" }}>
        <StackItem>
          <RadioGroup layout="horizontal" aria-labelledby={labelId} style={{ textAlign: "center" }}>
            <Radio value="1" label="YES" onChange={(e:any)=>{setResolverGroupHelped(e.currentTarget.value)}} />
            <Radio value="0" label="NO" onChange={(e:any)=>{setResolverGroupHelped(e.currentTarget.value)}}/>
          </RadioGroup>
        </StackItem>
        </Stack>
        <Stack style={{ flexDirection: "row" }}>
        <StackItem>
          <Label style={{ textAlign: "left" ,verticalAlign:"middle"}} id={labelId2}>Did the tool provide an accurate and comprehensive answer to help solve the problem?</Label>
        </StackItem>
        </Stack>
        <Stack style={{ flexDirection: "row" }}>
        <StackItem>
          <RadioGroup layout="horizontal" aria-labelledby={labelId2} style={{ textAlign: "center" }}>
          <Radio value="1" label="YES" onChange={(e:any)=>{setSolutionAccurate(e.currentTarget.value)}} />
            <Radio value="0" label="NO" onChange={(e:any)=>{setSolutionAccurate(e.currentTarget.value)}}/>
          </RadioGroup>
        </StackItem>
        </Stack>
        <Stack style={{ flexDirection: "row" }}>
        <StackItem>
          <Label style={{ textAlign: "left" ,verticalAlign:"middle"}} id={labelId3}>Were the provided documents relevant to the problem?</Label>
        </StackItem>
        </Stack>
        <Stack style={{ flexDirection: "row" }}>
        <StackItem>
          <RadioGroup layout="horizontal" aria-labelledby={labelId3} style={{ textAlign: "center" }}>
          <Radio value="1" label="YES" onChange={(e:any)=>{setDocumentRelevant(e.currentTarget.value)}} />
            <Radio value="0" label="NO" onChange={(e:any)=>{setDocumentRelevant(e.currentTarget.value)}}/>
          </RadioGroup>
        </StackItem>
      </Stack>
    </div>
  );
};