import { observer } from "mobx-react";
import React from "react";
import { useAppStore } from "../..";
import styles from "./Alert.module.css";

const Alert = observer(() => {
    const appStore = useAppStore();

    if (appStore?.toggleAlert) {
        return (
            <div className={styles.Alert_Container}>
                <h3>{appStore.alertMessage}</h3>

                <button onClick={() => appStore.closeAlert()}>
                    <i className="fa-solid fa-x"></i>
                </button>
            </div>
        );
    } else {
        return <></>;
    }
});

export default Alert;
