import {
    observable,
    computed,
    action,
    makeAutoObservable,
    toJS,
    autorun,
    values,
  } from 'mobx';


export  default class AppStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable userName:string="";
  @observable userDetails:any={};
  @observable sbgList:string[]=[];
  @observable previousQueryFeedback:number=-1;
  @observable citationsFeedback: any={};
  @observable usedGenAI:number=-1;
  @observable displayUserName:string ="";
  @observable previousSessionRatingProvided:boolean=false;
  @observable bannerText:string="";
  @observable isConfigPanelOpen:boolean=false;
  @observable toggleAlert: boolean = false;
  @observable alertMessage: string = "";

  @action
  setUserName=(userName :string)=>{
    this.userName=userName;
  }    
   
 @action
 setUserDetails=(userDetails :any)=>{
    // const Details={ "displayName": "S H, Bharath", "previousRatingProvided": false, "sbg_gbe": { "PMT": [ { "gbe": "UOP", "product_family": [] }, { "gbe": "HPS", "product_family": ["pf1","pf2"] } ] ,"AERO": [ { "gbe": "AeroGbe1", "product_family": ["Aeropf1","pf2"] }, { "gbe": "AeroGbe2", "product_family": ["Aeropf2","pf5"] } ]} , "sessionid": "7c96934d-8a3d-45ce-8a6e-a1dec7087c1c", "userName": "Bharath SH" }
    this.userDetails=userDetails;
    // const keys = Object.keys(userDetails.sbg_gbe) as (keyof typeof userDetails)[];
    // //get all the values object with whose key is "gbe" ?
    // keys.map((x)=>{
    //   this.sbgList.push(x.toString());
    // })
    
 }

 @action
 setPreviousQueryFeedback=(value:number)=>{
  this.previousQueryFeedback=value
 }

 @action 
 setCitationsFeedback=(value:any)=>{
  this.citationsFeedback=value;
 }

 @action
 setUsedGenAI=(value:number)=>{
 this.usedGenAI=value
 }

 @action
 setDisplayUserName=(value:string)=>{
  this.displayUserName=value;
 }
 @action
 setPreviousSessionRatingProvided=(value:boolean)=>{
  this.previousSessionRatingProvided=value;
 }

 @action
 setBannerText=(value:string)=>{
  this.bannerText=value;
 }

 @action
 setToggleConfigPanelOpen=()=>{
  this.isConfigPanelOpen = !this.isConfigPanelOpen;
 }

 @action openAlert = (val: string) => {
  this.toggleAlert = true;
  this.alertMessage = val;

  setTimeout(() => {
      this.toggleAlert = false;
      this.alertMessage = "";
  }, 3000);
};

@action closeAlert = () => {
  this.toggleAlert = false;
  this.alertMessage = "";
};


}

//  export default new AppStore();

autorun(() => {
})
