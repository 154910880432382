import { Stack, StackItem, Text, TextField } from "@fluentui/react";

import styles from "./ModalIntentButtons.module.css";
import { Fragment, useEffect, useState } from "react";
import { Feedback } from "../Feedback/Feedback";
import { Spinner } from "../Spinner";

interface Props {
    closeModal: (value: boolean, intent: string) => void;
}

export const ModalIntentButtons = ({ closeModal }: Props) => {
    const [intent, setIntent] = useState<string>("");
    return (
        // <div className={styles.modale}>
        <div className={styles.modalDialogIntent}>
            <div className={styles.modalHeader}>
                <h2>Please select one of the option below to proceed further.</h2>
            </div>
            <div className={styles.modalBody}>
                <ol className={styles.intentButton}>
                    <li>
                        <p>Provide more information on the</p>
                        <button className={styles.btnGoBack} onClick={() => closeModal(false, "resolver")}>
                            CORRECT RESOLUTION GROUP
                        </button>
                    </li>
                    <li>
                        <p>Provide more information on the</p>
                        <button className={styles.btnGoBack} onClick={() => closeModal(false, "resolution")}>
                            SOLVING THE ISSUE
                        </button>
                    </li>
                    <li>
                        <p>Provide more guidance from the</p>
                        <button className={styles.btnGoBack} onClick={() => closeModal(false, "knowledge")}>
                            KNOWLEDGE ARTICLES
                        </button>
                    </li>
                    <li>
                        <p>Say more about recent</p>
                        <button className={styles.btnGoBack} onClick={() => closeModal(false, "change")}>
                            CHANGE REQUESTS
                        </button>
                    </li>
                    <li>
                        <p>None of the above:</p>
                        <button className={styles.btnGoBack} onClick={() => closeModal(false, "generic")}>
                            START A NEW INQUIRY
                        </button>
                    </li>
                </ol>
            </div>
            {/* <div className={styles.modalFooter}>
            {showSpinner ? (
                <Spinner />
            ) : (
                <Stack horizontal horizontalAlign="space-evenly" style={{ width: "100%" }}>
                {
                    <StackItem>
                    <button
                        onClick={() => {
                        closeModal(false, rating, comment, true);
                        SetShowSpinner(!showSpinner);
                        }}
                        className={styles.btnGoBack}
                        id="btn_ingres"
                    >
                        BACK TO CHAT
                    </button>
                    </StackItem>
                }

                <StackItem>
                    <button
                    onClick={() => {
                        closeModal(false, rating, comment, false);
                        SetShowSpinner(!showSpinner);
                    }}
                    className={styles.btn}
                    id="btn_ingresar"
                    disabled={isCloseDisabled}
                    >
                    SUBMIT
                    </button>
                </StackItem>
                </Stack>
            )}
            </div> */}
        </div>
        // </div>
    );
};
