export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}

export type AskRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
    searchType?: string;
    semanticSearch?: string;
    sbg?: string | null;
    gbe?:string|null;
    product_family?:string[];
    queryFeedback?:string|null;
    citations_feedback?:{};
    caseNumber:string;
    intent: string;
    aiAgent: boolean;
};

export type answerRequest = {
    content: string;
    title: string;
}

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type AskResponse = {
    answer: answerRequest[];
    data_points: string[];
    error:any;
    thoughts:any;
};

export type ChatTurn = {
    user: string;
   // bot?: string;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type EndChatRequest = {
    previousRating:number;
    overallRating:number;
    overallComments:string;
    usedGenAI:string;
    resolverGroupHelped:string;
    documentRelevant:string;
    solutionAccurate:string;
    data_points:{};
};

