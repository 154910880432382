import { useEffect, useMemo, useState } from "react";
import { Stack, IconButton, StackItem } from "@fluentui/react";
import DOMPurify from "dompurify";
import { parseSupportingContentItem } from "../SupportingContent/SupportingContentParser";
import {
    ThumbLike20Regular, LightbulbRegular, Lightbulb20Regular, ThumbLike16Regular, ThumbDislike16Regular, FluentIconsProps, iconFilledClassName,
    iconRegularClassName,
    ThumbLike16Filled,
    ThumbDislike16Filled,
    PersonCircle24Filled,
    Bot24Filled,
} from '@fluentui/react-icons';
import styles from "./Answer.module.css";

import { AskResponse, getCitationFilePath, answerRequest } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { makeStyles } from '@fluentui/react-components';
import { CopyButton } from "../CopyButton";
import { observer } from "mobx-react";
import { useAppStore } from "../..";
import botLogo from '../../assets/H_vector.svg';
import Typewriter from 'typewriter-effect';
import Typed from 'react-typed';

interface Props {
    answer: any;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    supportingContent: string[];
}

const iconStyleProps: FluentIconsProps = {
    primaryFill: 'purple',
    className: 'iconClass',
};

const useIconStyles = makeStyles({
    icon: {
        ':hover': {
            [`& .${iconFilledClassName}`]: {
                display: 'none',
            },
            [`& .${iconRegularClassName}`]: {
                display: 'inline',
            },
        },
    },
});

export const Answer = observer(({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    supportingContent

}: Props) => {
    const appStore = useAppStore();
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.content, onCitationClicked), [answer]);
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    const start = "Searched for:<br>";
    const end = "<br>Prompt:";
    const str = "";
    let citationArray :any = [];
    let index :any = 0;
    const string_between_strings = (startStr: any, endStr: any, str: string) => {
        let pos = str.indexOf(startStr) + startStr.length;
        return str.substring(pos, str.indexOf(endStr, pos));
    };
    const sanitizedThoughts = DOMPurify.sanitize(string_between_strings(start, end, str));
    const [likeClicked, setLikeClicked] = useState(false);
    const [dislikeClicked, setDislikeClicked] = useState(false);
    const [citationEmpty, setCitationEmpty] = useState(true);

    const onLikeClicked = () => {
        //    const val = likeClicked == true?false :true;
        setDislikeClicked(false)
        setLikeClicked(!likeClicked);



    }
    const onDislikeClicked = () => {
        // const val = dislikeClicked == true?false :true;
        setLikeClicked(false);
        setDislikeClicked(!dislikeClicked);
    }

    const removeHTMLtagsAnswer = (content: any) => {
        const parsedAnswer = content.replaceAll(/<[^>]+>/g, '');
        const result = parsedAnswer.replaceAll(/1|2|3|4|5|6|7|8|9|0/g, '');
        return result;
    }

    useEffect(() => {
        if (dislikeClicked == true) {
            setLikeClicked(false)
            appStore.setPreviousQueryFeedback(0)
        } else if (likeClicked == true) {
            setDislikeClicked(false)
            appStore.setPreviousQueryFeedback(1)
        } else {
            appStore.setPreviousQueryFeedback(-1)
        }


        // if(dislikeClicked == true ){
        //     setDislikeClicked(!likeClicked);
        //      setLikeClicked(!likeClicked);
        // }

    }, [likeClicked, dislikeClicked])



    return (
        <Stack  horizontal horizontalAlign="space-between" style={{width:"100%"}}>
        <StackItem style={{width:"100%"}}>
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between" style={{width:"100%"}}>
            {/* <Stack.Item>
                <span style={{ fontSize: "16px" }}>Searched for:</span>
                <div className={styles.searchedFor} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
            </Stack.Item> */}

            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <Stack.Item>
                        <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                    </Stack.Item>
                    <Stack.Item grow>
                        <p className={styles.TechGPT}>ItCopilot:</p>
                        <div className={styles.answerText}>
                            <h3>{answer.title}</h3>
                            <Typed
                                strings={[sanitizedAnswerHtml]}
                                typeSpeed={1}
                            />
                            {/* <Typewriter
                            // options={{
                            //     strings: [sanitizedAnswerHtml],
                            //     autoStart: true
                            //     // loop: true,
                            // }}
                            onInit={(typewriter) => {
                                typewriter.typeString(sanitizedAnswerHtml).pauseFor(999999999).start();
                            }}
                        /> */}
                        </div>
                        {/* <div className={styles.typewriter}>
                            <span>{parsedAnswer.answertext}</span>
                        </div> */}
                        {/* <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div> */}
                    
                        {!!parsedAnswer.citations.length && (
                                     <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                                      {!citationEmpty && <span className={styles.citationLearnMore}>Citations:</span>}  
                                        {supportingContent.map((x) => {
                                            const parsed = parseSupportingContentItem(x);
                                            const path = parsed.docUrl;
                                            const citation = parsedAnswer.citations.some(str => {
                                                if(str.includes(parsed.title) && !citationArray.includes(str)) {
                                                    citationArray.push(str);
                                                    if(citationEmpty){
                                                     setCitationEmpty(false);
                                                    }
                                                    return true;
                                                }
                                                else {
                                                    return false;
                                                }
                                            });
                                            
                                            if (citation) {
                                                return (
                                                    <a key={index} className={styles.citation} title={parsed.title} onClick={() => onCitationClicked(`${path}:${parsed.title}`)}>
                                                        {`${++index}. ${parsed.title}`}
                                                    </a>
                                                );
                                            }
                                        })}
                                      
                                </Stack>
                        )}
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            {/* <Stack.Item  >
                <Stack horizontal horizontalAlign="space-between" >
                    <Stack.Item align="start" >
                        <CopyButton content={parsedAnswer.answertext} />
                    </Stack.Item  >
                    <Stack.Item align="end">
                        <Stack horizontal horizontalAlign="space-between" >
                            <div>
                                <button onClick={() => onLikeClicked()} type="button" className={`ms-Button ms-Button--icon ${styles.root128}`} data-is-focusable="true" title="Liked the response" aria-label="Liked the response" style={{ color: "black" }}>
                                    <span className="ms-Button-flexContainer flexContainer-129" data-automationid="splitbuttonprimary">
                                        <i data-icon-name="Lightbulb" aria-hidden="true" className="ms-Icon root-105 css-137 ms-Button-icon icon-131" >
                                            {likeClicked ? <ThumbLike16Filled /> : <ThumbLike16Regular />}
                                        </i>
                                    </span>
                                </button>
                                <button onClick={() => onDislikeClicked()} type="button" className={`ms-Button ms-Button--icon ${styles.root128}`} data-is-focusable="true" title="Disliked the response" aria-label="Disliked the response" style={{ color: "black" }}>
                                    <span className="ms-Button-flexContainer flexContainer-129" data-automationid="splitbuttonprimary">
                                        <i data-icon-name="Lightbulb" aria-hidden="true" className="ms-Icon root-105 css-137 ms-Button-icon icon-131" >
                                            {dislikeClicked ? <ThumbDislike16Filled /> : <ThumbDislike16Regular />}
                                        </i>
                                    </span>
                                </button>
                       
                            </div>
                        </Stack>
                    </Stack.Item  >
                </Stack>
            </Stack.Item> */}
            <Stack.Item align="end" >
                <span className={styles.warningText}><b style={{color:'indianred'}}>Please Note:</b> Content generated by AI may not be accurate. Users must exercise their own judgment and verify the information before use.</span>
            </Stack.Item>
         {/* <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        /> */}

            {/* {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                    {`${++i}. ${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )} */}

            {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {parsedAnswer.followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            <Stack.Item align="end">
            </Stack.Item>
        </Stack>
        </StackItem>
        <StackItem  style={{height:"100%"}}  align="end" >
            <Stack  verticalAlign="space-between">
                <StackItem className={styles.copyContainer} style={{borderTopRightRadius:"8px",color:"#1275b7"}}>
                <CopyButton content={removeHTMLtagsAnswer(sanitizedAnswerHtml)} />
                </StackItem>
                <Stack.Item align="end" className={styles.copyContainer} style={{borderBottomRightRadius:"8px"}}>
                        <Stack horizontal  >
                            {/* <AnswerIcon /> */}
                            <StackItem>
                                <button onClick={() => onLikeClicked()} type="button" className={`ms-Button ms-Button--icon ${styles.root128}`} data-is-focusable="true" title="Liked the response" aria-label="Liked the response" style={{ color: "green" }}>
                                    <span className="ms-Button-flexContainer flexContainer-129" data-automationid="splitbuttonprimary">
                                        <i data-icon-name="Lightbulb" aria-hidden="true" className="ms-Icon root-105 css-137 ms-Button-icon icon-131" >
                                            {likeClicked ? <ThumbLike16Filled /> : <ThumbLike16Regular />}
                                        </i>
                                    </span>
                                </button>
                                </StackItem>
                                <StackItem>
                                <button onClick={() => onDislikeClicked()} type="button" className={`ms-Button ms-Button--icon ${styles.root128}`} data-is-focusable="true" title="Disliked the response" aria-label="Disliked the response" style={{ color: "red" }}>
                                    <span className="ms-Button-flexContainer flexContainer-129" data-automationid="splitbuttonprimary">
                                        <i data-icon-name="Lightbulb" aria-hidden="true" className="ms-Icon root-105 css-137 ms-Button-icon icon-131" >
                                            {dislikeClicked ? <ThumbDislike16Filled /> : <ThumbDislike16Regular />}
                                        </i>
                                    </span>
                                </button>
                                </StackItem>
                                {/* <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        /> */}
                          
                        </Stack>
                    </Stack.Item  >
            </Stack>
        
        </StackItem>
        </Stack>
    );
});
