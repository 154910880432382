type ParsedSupportingContentItem = {
    title: string;
    content: string;
    contentCopy : string;
    targetAudience:string
    docUrl:string,
    citationTitle: string
};

export function parseSupportingContentItem(item: string): ParsedSupportingContentItem {

    if(item !== ""){
    //"docUrl : /content/dam/honeywell-edam/pmt/hps/vault/iics-xml/pmt-hps-pd23z01c.pdf , targetAudience : anonymous , pmt-hps-pd23z01c.pdf : Honeywell Automation and Control Solutions PKS\u2122 - TDC 3000\u00"
    // const [url,audience,...rest] = item.split(",");
    // const docUrl = url.split(": ")[1];
    // let targetAudience = audience.split(": ")[1];
    // targetAudience = targetAudience.trim();
    // const fullContent = rest.join(",")
    
    // // Assumes the item starts with the file name followed by : and the content.
    // // Example: "sdp_corporate.pdf: this is the content that follows".
    // const [titleElement, ...restParts] = fullContent.split(': ');
    // const parts = restParts.join(": ");
    // let title = titleElement.trim();
    // if(title.includes('ka')) {
    //     title = title.split('.')[0];
    // }
    const [...restContent] = item.split(/\r?\n/);
    let contentStr = restContent.join("\r\n");
    let contentCopy = contentStr;

    // const desIndex =contentStr.indexOf("Description:");
    // const conIndex= contentStr.indexOf("Content:");

    // const content = contentStr.substring(0, desIndex) + 
    // '<span class="hilite">' + 
    // contentStr.substring(desIndex, desIndex+12) +
    // '</span>' +
    // contentStr.substring(desIndex+12,conIndex) +
    // '<span class="hilite">' + 
    // contentStr.substring(conIndex, conIndex+8) +
    // '</span>' +
    // contentStr.substring(conIndex+8,contentStr.length);


    const keywords =["Incident Number","Knowledge ID","Change ID"];
    let contentStr1="";
   keywords.forEach((key)=>{
    if(contentStr.indexOf(key) > -1){
         contentStr1 = contentStr.substring(contentStr.indexOf(key)+key.length);
        if(contentStr1.indexOf(":")>-1){
        contentStr1=contentStr1.split(":")[1];
        }
        if(contentStr1.indexOf(",")>-1){
        contentStr1=contentStr1.split(",")[0];
        }
        if(contentStr1.indexOf("\n")>-1){
        contentStr1=contentStr1.split("\n")[0];
        }
    }
   })
   const content = contentStr
   const title = contentStr1;
   const docUrl = "";
   const targetAudience = "";
   const citationTitle = "";
    return {
        title,
        content,
        contentCopy,
        docUrl,
        targetAudience,
        citationTitle
    };

    
    }else{
        const  title="";
        const content="";
        const contentCopy="";
        const docUrl="";
        const targetAudience="";
        const citationTitle = "";
        return {
            title,
            content,
            contentCopy,
            docUrl,
            targetAudience,
            citationTitle
        };

    }
   
}
