import { observer } from "mobx-react";
import { config } from "../config";
import { generateCodeChallenge, generateCodeVerifier, isAccessTokenNeedsToBeRefreshed } from "./apiUtils";
import { AskRequest, AskResponse, ChatRequest, EndChatRequest } from "./models";



const handleSBGSpecificUrl = () => {
    return config.backendUrl;
    // switch (localStorage.getItem("ApplicationGroup")) {
    //     // case 'PMT': return config.backendUrlPMT;
    //     // case 'AERO': return config.backendUrlAERO;
    //     // case 'HBT': return config.backendUrlHBT;
    //     // case 'SPS': return config.backendUrlSPS;
    //     default: return config.backendUrl;
    //   }
}

export async function askApi(options: AskRequest): Promise<AskResponse> {
    if (isAccessTokenNeedsToBeRefreshed()) {
        getNewAccessToken();
    }
    const backendUrl = handleSBGSpecificUrl();
    const response = await fetch(`${backendUrl}/ask`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory
            }
        }),
        redirect: "follow",
        referrer: location.origin,
        referrerPolicy: "strict-origin-when-cross-origin",
        mode: "cors"
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest, userName: string): Promise<AskResponse> {
    if (isAccessTokenNeedsToBeRefreshed()) {
        getNewAccessToken();
    }
    const backendUrl = handleSBGSpecificUrl();
    const token = localStorage.getItem("Token") as string
    const response = await fetch(`${backendUrl}/chat/${userName}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': token
        },
        credentials: 'include' as RequestCredentials ,
        body: JSON.stringify({
            // {
            // history: options.history,
            // "overrides":{"semantic_ranker":false,
            // "semantic_captions":false, 
            // "search_type":"vec", 
            // "semantic_search":"on",
            // "temperature":0,
            // "top":20, 
            // "suggest_followup_questions":false,
            // "Application":"SAP Access Management",
            // "queryFeedback":-1,
            // "citations_feedback":{}}
            // }
            history: options.history,
            approach: options.approach,
            overrides: {
            "semantic_ranker":false,
            "semantic_captions":false, 
            "search_type":"vec", 
            "semantic_search":"on",
            "temperature":0,
            "top":20, 
            "suggest_followup_questions":false,
            "Application":localStorage.getItem("ApplicationGroup"),
            "caseNumber" : options.overrides?.caseNumber,
            "queryFeedback":-1,
            "intent" : options.overrides?.intent,
            "aiAgent": options.overrides?.aiAgent, 
            "citations_feedback":{}}
        }),
        redirect: "follow",
        referrer: location.origin,
        referrerPolicy: "strict-origin-when-cross-origin",
        mode: "cors"
    });
    console.log(response);
    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export function getCitationFilePath(citation: string): string {
    if (isAccessTokenNeedsToBeRefreshed()) {
        getNewAccessToken();
    }
    return `/content/${citation}`;
}

export async function getAccessToken(code: string, code_verifier: string): Promise<any> {
    // const code_verifier = sessionStorage.getItem("code_verifier")?.toString();
    if (code_verifier !== null && code_verifier !== undefined) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("client_id", config.clientId);
        urlencoded.append("scope", "openid offline_access");
        urlencoded.append("code", code);
        urlencoded.append("redirect_uri", config.baseUrl);
        urlencoded.append("grant_type", "authorization_code");
        // urlencoded.append("client_secret", "6OZ8Q~t1VGDCz48ib7-oK_fra8UsBpNft~nQhbKC");
        urlencoded.append("code_verifier", code_verifier);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow" as RequestRedirect
        };

        const response = await fetch("https://login.microsoftonline.com/96ece526-9c7d-48b0-8daf-8b93c90a5d18/oauth2/v2.0/token", requestOptions);

        return response.json();
        //   .then(response => response.text())
        //   .then(result => {
        //     console.log(result)
        //     return result})
        //   .catch(error => { console.log('error', error)

        //   return error});
    }
}

export function getNewAccessToken() {
    const refreshToken = localStorage.getItem("refreshToekn");
    if (refreshToken != null) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        // myHeaders.append("Cookie", "buid=0.AQcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA.AQABAAEAAAAtyolDObpQQ5VtlI4uGjEPZBR0moZ3-720sdawUe95qGiE0NYffWUobf5gFKWRnQu3lBkGlFju1IxFCSNWRHHtJ-bS4aY5Mk5Is01dT9VOr2Fhyj8T82chp8Nxkjch67kgAA; fpc=AvDLjvOJAkBBjZ-NzddvtOh8Ap3YAgAAAJqAZtwOAAAA; stsservicecookie=estsfd; x-ms-gateway-slice=estsfd");

        var urlencoded = new URLSearchParams();
        urlencoded.append("client_id", config.clientId);
        urlencoded.append("scope", "openid offline_access User.Read");
        urlencoded.append("refresh_token", refreshToken);
        urlencoded.append("grant_type", "refresh_token");
        // urlencoded.append("prompt","admin_consent")

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            mode: "no-cors" as RequestMode,
            redirect: "follow" as RequestRedirect
        };

        fetch("https://login.microsoftonline.com/96ece526-9c7d-48b0-8daf-8b93c90a5d18/oauth2/v2.0/token", requestOptions)
            .then(response => response.json())
            .then(response => {
                localStorage.setItem("Token", response.access_token);
                localStorage.setItem("refreshToekn", response.refresh_token);
                localStorage.setItem("expirationTime", response.expires_in);
                localStorage.setItem("startTime", JSON.stringify(new Date()));                
            })
            .catch(error => console.log("error", error));
    }
}

export async function getUserDetails(userName: string) {
    try {
        if (isAccessTokenNeedsToBeRefreshed()) {
            getNewAccessToken();
        }
        const token = localStorage.getItem("Token") as string
        var requestOptions = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                'Authorization': token
            },
            referrer: location.origin,
            credentials: 'include' as RequestCredentials ,
            referrerPolicy: "strict-origin-when-cross-origin" as ReferrerPolicy,
            mode: "cors" as RequestMode,
            redirect: 'follow' as RequestRedirect
        };
     const result = await fetch(`${config.backendUrl}/getUserDetails/${userName}`, requestOptions)
    //   const result = await fetch(`${window.location.origin}/userDetails.json`, requestOptions)
            .then(response => response.json())
            .then(result => {
                return result;
            })
            .catch(error => console.log('error', error));
        console.log(result);
      return result;
    }
    catch (exception) {
        console.log(exception)
    }
}

export async function endChatApi(options:EndChatRequest,userName:string){
    if (isAccessTokenNeedsToBeRefreshed()) {
        getNewAccessToken();
    }
    const backendUrl = handleSBGSpecificUrl();
    const token = localStorage.getItem("Token") as string
    const response = await fetch(`${backendUrl}/endChat/${userName}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': token
        },
        credentials: 'include' as RequestCredentials ,
        body: JSON.stringify({...options}),
        redirect: "follow",
        referrer: location.origin,
        referrerPolicy: "strict-origin-when-cross-origin",
        mode: "cors"
    });

    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}


export async function createSession(userName:string){
    try {
        if (isAccessTokenNeedsToBeRefreshed()) {
            getNewAccessToken();
        }
        const token = localStorage.getItem("Token") as string
        var requestOptions = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                'Authorization': token
            },
            referrer: location.origin,
            credentials: 'include' as RequestCredentials ,
            referrerPolicy: "strict-origin-when-cross-origin" as ReferrerPolicy,
            mode: "cors" as RequestMode,
            redirect: 'follow' as RequestRedirect
        };
       const backendUrl = handleSBGSpecificUrl();
       const result = await fetch(`${backendUrl}/createSession/${userName}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                return result;
            })
            .catch(error => console.log('error', error));
        return result;
    }
    catch (exception) {
        console.log(exception)
    }
}