import { useRef, useState, useEffect, useCallback } from "react";
import {
    Checkbox,
    Panel,
    DefaultButton,
    TextField,
    SpinButton,
    ChoiceGroup,
    IChoiceGroupOption,
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
    Stack,
    StackItem
} from "@fluentui/react";
import { SparkleFilled, Power24Filled } from "@fluentui/react-icons";
import { Button } from '@fluentui/react-components';
import { Switch } from '@fluentui/react-switch';
import { config } from "../../config";
import testData from "./testData.json";
import styles from "./Chat.module.css";

import { chatApi, Approaches, AskResponse, ChatRequest, ChatTurn, EndChatRequest, endChatApi, getUserDetails, createSession } from "../../api";
import { CustomAnswer, Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import backGroundImg from "../../assets/hon-sustainability.png";
import { Feedback } from "../../components/Feedback/Feedback";
import { Modal } from "../../components/Modal/Modal";
import { ModalIntentButtons } from "../../components/ModalIntentButtons/ModalIntentButtons";
import { ModalPrivacy } from "../../components/Modal/ModalPrivacy";
import { ModalAIAgentDisclaimer } from "../../components/Modal/ModalAIAgentDisclaimer";
import { ModalNotification } from "../../components/Modal/ModalNotification";
import { observer } from "mobx-react";
import { useAppStore } from "../..";
import { SupportingCard } from "../../components/SupportingCard/SupportingCard";
import { Spinner } from "../../components/Spinner";
import botLogo from '../../assets/H_vector.svg';
import AppStore from "../../store/AppStore";
import DropdownSearch from "../../components/Answer/DropdownSearch";


const Chat = observer(() => {
    const appStore = useAppStore();
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(appStore.isConfigPanelOpen);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [searchType, setSearchType] = useState<string | null>(localStorage.getItem("SearchType") ? localStorage.getItem("SearchType") : "Vector");
    const [semanticSearch, setSemanticSearch] = useState<string>("on");
    const [retrieveCount, setRetrieveCount] = useState<number>(20);
    const [temperature, setTemperature] = useState<number>(0);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(false);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);
    const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
    const [sbg, setSbg] = useState<string | null>(localStorage.getItem("ApplicationGroup") ? localStorage.getItem("ApplicationGroup") : "");
    const [productFamily, setProductFamily] = useState<string[]>(JSON.parse(localStorage.getItem("ProductFamilySelection_v2") || '{}').length > 0 ? JSON.parse(localStorage.getItem("ProductFamilySelection_v2") || '{}') : ["Select All"]);
    const [sbgOptions, setSbgOptions] = useState<IDropdownOption[]>([]);
    const [gbeOptions, setGbeOptions] = useState<IDropdownOption[]>([]);
    const [productFamilyOptions, setProductFamilyOptions] = useState<any[]>([]);
    const [pfOptions, setPfOptions] = useState<any>([]);
    const [caseNumber, setCaseNumber] = useState<string>("");
    const [caseNumberFlow, setCaseNumberFlow] = useState<string>("");
    const [userDetails, setUserDetails] = useState<any>({});
    const [startOver , setStartOver] = useState<boolean>(false);
    const [selectedItem, setSelectedItems] = useState(null);
    const [showPrivacyNote, setShowPrivacyNote] = useState(true);
    const [showDisclaimerModal, setShowDisclaimerModal] = useState<boolean>(false);
    const [showNotificationModal, setShowNotificationModal] = useState<boolean>(false);
    const [toggleDetails, setToggleDetails] = useState<boolean>(false);
    const [showIntentButtonModal, setShowIntentButtonModal] = useState<boolean>(false);
    const [question, setQuestion] = useState<string>("");
    const [aiAgentChecked, setAiAgentChecked] = useState<boolean>(localStorage.getItem("AiAgentChecked") === "true" ? true : false);
   

    const logoutBtn = useRef<any>(null);


    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 300 }
    };
    const options: IDropdownOption[] = [
        { key: "HPS", text: "HPS" },
        { key: "AERO", text: "AERO" },
        { key: "HBT", text: "HBT" },
        { key: "SPS", text: "SPS" }
    ];

    useEffect(() => {
        if (Object.keys(userDetails).length > 0) {
            const data: IDropdownOption[] = [];
            data.push({ key: "All", text: "All" })
            userDetails.applications.map((x :string) => {
                data.push({ key: x, text: x })
            });
            setSbgOptions(data);
        }
    }, [isConfigPanelOpen, userDetails])
    const onDropDownSearch=(
        searchValue: string,
        initialValues: IDropdownOption[],
      ): IDropdownOption[]=>{
        console.log(initialValues,"..initialValues")
        if (searchValue==null || searchValue=="" || searchValue==undefined) {
          setSbgOptions(initialValues);
          return [...initialValues];
        }
    
        //OR FIlter but whatever you want...
        const filteredOptions = [...initialValues].filter(
          (i) =>
            i.text.includes(searchValue),
        );
        return filteredOptions;
      }
    useEffect(() => {
        if (appStore.previousSessionRatingProvided == false) {
            lastQuestionRef.current = "";
            setShowFeedbackModal(true);
            setShowPrivacyNote(false);

        }
    }, [appStore.previousSessionRatingProvided])
    const handleInputQuestion = (question: string) => {
        if (caseNumberFlow == "true" && caseNumber === "") {
            setCaseNumber(question);
        } 
        else if (answers.length > 0 && !aiAgentChecked) {
            setQuestion(question);
            setShowIntentButtonModal(true);
        }
        else if (caseNumberFlow == "true" && caseNumber !== "") {
            makeApiRequest(question,"")
        } else {
            makeApiRequest(question,"")
        }

    }

    const makeApiRequest = async (question: string, intent: string) => {
        console.log(question,"..question");
        lastQuestionRef.current = question;
        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        localStorage.setItem("ApplicationGroup", sbg ? sbg : "");
        console.log(answers,"..answers")
        try {
        //   const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            console.log(appStore.previousQueryFeedback,"..history")
            const request: ChatRequest = {
                history:[{"user":question }],
                approach: Approaches.ReadRetrieveRead,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions,
                    searchType: searchType !== null ? searchType : "Vector",
                    semanticSearch: semanticSearch,
                    temperature: temperature,
                    product_family: productFamily,
                    queryFeedback: appStore.previousQueryFeedback,
                    citations_feedback: appStore.citationsFeedback,
                    caseNumber: caseNumber,
                    intent: intent ? intent : "",
                    aiAgent: aiAgentChecked

                }
            };
        const result = await chatApi(request, appStore.userName);
       // const result = testData;
        setAnswers([...answers, [question, result]]);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const clearChat = () => {
        // lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        // setAnswers([]);
        setShowFeedbackModal(true);
        setStartOver(true)
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading, answers]);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onsearchTypeChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        setSearchType(option ? option.key : "Vector");
        localStorage.setItem("SearchType", option ? option.key : "Vector");

    };

    const sessionCreate = async (option: any) => {
        localStorage.setItem("ApplicationGroup", option);
        var sessionDeatils = await createSession(appStore.userName);
        if (sessionDeatils?.conversation?.length > 0 && sessionDeatils.previousRatingProvided == false) {
            appStore.setPreviousSessionRatingProvided(sessionDeatils.previousRatingProvided);
        } else {
            appStore.setPreviousSessionRatingProvided(true);
        }
    };

    const onSbgChange = (option?: IDropdownOption) => {
        setSbg(option ? option.text : "");
        sessionCreate(option ? option.text : "");
        localStorage.setItem("ApplicationGroup", option ? option.text : "");
    };

    const onChangeAIAgent = useCallback(
        (ev:any) => {
          setAiAgentChecked(ev.currentTarget.checked);
          localStorage.setItem("AiAgentChecked", ev.currentTarget.checked);
          if(ev.currentTarget.checked){
            setShowDisclaimerModal(true);
          }
        },
        [setAiAgentChecked]
      );

    // const onSbgChange = (_ev?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    //     setSbg(option ? option.text : "");
    //     sessionCreate(option ? option.text : "");
    //     localStorage.setItem("ApplicationGroup", option ? option.text : "");
    // };

   
    const onSemanticSearchChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        setSemanticSearch(option ? option.key : "on");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onTemperatureChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setTemperature(parseFloat(newValue || "0"));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example,"");
    };

    const onShowCitation = (citation: string, index: number) => {

        const citationlink = getCitationLink()

        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            let citationIframeUrl;
            const citationPath = citation.split(":")[0];
            const citationTitle = citation.split(":")[1];
            citationIframeUrl = citationlink + citationPath;
            window.open(citationIframeUrl);
        }

        setSelectedAnswer(index);
    };

    const getCitationLink = () => {
        switch (localStorage.getItem("ApplicationGroup")) {
            case 'PMT': if (localStorage.getItem("GbeSelection") === "UOP") {
                            return config.citationLinkUOP;
                        }
                        else {
                            return config.citationLinkHPS;
                        }
            case 'AERO': return config.citationLinkAERO;
            case 'HBT': return config.citationLinkHBT;
            case 'SPS': return config.citationLinkSPS;
            default: return config.citationLinkHPS;
        }
    }

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const closeFeedbackModal = (value: boolean, rating: number, comment: string, isBackToChat: boolean) => {
        if (isBackToChat) {
            setShowFeedbackModal(false);
            setCaseNumberFlow("false");
        } else {
            lastQuestionRef.current = "";
            setAnswers([]);
            callEndChatApi(rating, comment);
        }
    }

    const closeIntentModal = (value: boolean, intent: string) => {
        setShowIntentButtonModal(value);
        makeApiRequest(question,intent);
    }

    const closePrivacyModal = (value: boolean) => {
        setShowPrivacyNote(false);
        if(appStore.bannerText.length > 0){
            setShowNotificationModal(true);
        }
    }

    const closeDisclaimerModal = (value: boolean) => {
        setShowDisclaimerModal(false);
    }

    const closeNotificationModal = (value: boolean) => {
        setShowNotificationModal(false);
    }

    const callEndChatApi = async (rating: number, comment: string) => {
        const request: EndChatRequest = {
            overallRating: rating,
            previousRating: appStore.previousQueryFeedback,
            overallComments: comment,
            usedGenAI: appStore.usedGenAI.documentRelevant,
            resolverGroupHelped: appStore.usedGenAI.resolverGroupHelped,
            documentRelevant: appStore.usedGenAI.documentRelevant,
            solutionAccurate: appStore.usedGenAI.solutionAccurate,
            data_points: appStore.citationsFeedback
        }
        const response = await endChatApi(request, appStore.userName)
        document.cookie = "sessionID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.location.reload();
        //    setShowFeedbackModal(false);
    };

    const handleYesNoClick = (value: string) => {
        setCaseNumberFlow(value);
    }

    const handleSettingClicked = () => {
        appStore.setToggleConfigPanelOpen();
    };

    useEffect(() => {
        // setIsConfigPanelOpen(appStore.isConfigPanelOpen);
        (async () => {
            // var userDetails = await getUserDetails(appStore.userName);
            // setUserDetails(userDetails)
            // appStore.setUserDetails(userDetails);
            setUserDetails(appStore.userDetails);
        })();

    }, [appStore.isConfigPanelOpen]);

    useEffect(() => {
        // Function to handle clicks outside of the component
        const handleClickOutside = (event: any) => {
            if (logoutBtn.current && !logoutBtn.current.contains(event.target)) {
                // Click occurred outside of the component, so trigger the callback
                setToggleDetails(false);
            }
        };

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup by removing the event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLogoutClicked = () => {
        window.location.href = config.logoutUrl;
    };


    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                {/* <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} /> */}
            </div>
            <div className={styles.chatRoot}>
                <div
                    id="backgroundImageDisplay"
                    style={{ backgroundImage: `url(${backGroundImg})`, backgroundColor: "#393838", backgroundBlendMode: "multiply" }}
                    className={styles.chatContainer}
                >
                    {showPrivacyNote == false && !showIntentButtonModal && !showDisclaimerModal && !showNotificationModal && (
                        <div className={styles.applicationFilterButton}>
                              <div className={styles.Logout_Btn} ref={logoutBtn} onClick={() => setToggleDetails(!toggleDetails)}>
                                <Power24Filled
                                    style={{
                                        color: toggleDetails ? "#ee3124" : "#fff"
                                    }}
                                />
                                {toggleDetails && (
                                    <div className={styles.Menu_List} onClick={e => e.stopPropagation()}>
                                        <p className={styles.Details}>
                                            <span style={{marginRight : '0.5rem'}}>
                                                <i className="fa-solid fa-user"></i>
                                            </span>
                                            {appStore.displayUserName}
                                        </p>
                                        <p className={styles.Details}>
                                            <span style={{marginRight : '0.5rem'}}>
                                                <i className="fa-solid fa-envelope"></i>
                                            </span>
                                            {appStore.userName}
                                        </p>
                                        <button onClick={handleLogoutClicked}>Log Out</button>
                                    </div>
                                )}
                            </div>
                            <div>
                                {" "}
                                <SettingsButton className={styles.commandButton} onClick={() => handleSettingClicked()} />{" "}
                            </div>
                            <div className={styles.aiAgentDiv}>
                                <span className={styles.aiAgent} >AI Agent:</span>
                                <Switch checked={aiAgentChecked}  onChange={onChangeAIAgent} />
                            </div>
                        </div>
                    )}
                    {/* <h2 className={styles.chatEmptyStateSubtitle}>Ask anything or try an below example</h2> */}
                    {!lastQuestionRef.current && showFeedbackModal == false && showPrivacyNote == false && !showIntentButtonModal && !showDisclaimerModal && !showNotificationModal ? (
                        <div className={`${styles.chatMessageStream} ${styles.chatText}`}>
                            <div className={styles.chatMessageGpt}>
                                <div className={styles.chatEmptyStateTitle}>
                                    <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                                    <div>
                                        <p style={{ fontSize: "20px" }}>
                                            {" "}
                                            Hello, <span style={{ fontWeight: "bold" }}>{appStore.displayUserName}</span> Welcome to the Honeywell IT CoPilot.
                                        </p>
                                        {sbg !== "" ? (
                                            <>
                                                {" "}
                                                <p>
                                                    To get the most of the tool be as specific as possible when describing what you and the customer needs from
                                                    the response.{" "}
                                                </p>
                                                <span>
                                                    <span style={{ fontSize: "18px", fontWeight: "bold" }}>Note : </span>
                                                    <span>
                                                        Your selected Application Group is <span style={{ fontWeight: "bold" }}>{sbg} </span>
                                                    </span>
                                                    <p>Your sessions are recorded for quality and training purposes.</p>
                                                </span>
                                            </>
                                        ) : (
                                            <p> Please select the Application Group from the settings to continue.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {sbg !== "" && <div className={styles.chatMessageGpt}>
                                    <div className={styles.chatEmptyStateTitle}>
                                        <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                                        {/* <div> */}
                                        <Stack horizontal horizontalAlign="space-between" style={{ width: "100%" }} >
                                            <StackItem className={styles.chatText}> Do you have an Incident Number ? </StackItem>
                                            <StackItem align="end">
                                                <Button style={{ marginRight: "20px" }} className={styles.buttonPrimary} onClick={() => handleYesNoClick("true")}>YES</Button>
                                                <Button className={styles.buttonPrimary} onClick={() => handleYesNoClick("false")}>NO</Button>
                                            </StackItem>
                                        </Stack>
                                    </div>
                                </div>}

                                {caseNumberFlow == "true" && <div className={styles.chatMessageGpt}>
                                    <div className={styles.chatEmptyStateTitle}>
                                        <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                                        <div>
                                            <p> Enter the incident number below  </p>
                                        </div>
                                    </div>
                                </div>}

                                {caseNumberFlow == "false" && <div className={styles.chatMessageGpt}>
                                    <div className={styles.chatEmptyStateTitle}>
                                        <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                                        <div>
                                            <p>  Enter your question below </p>
                                        </div>
                                    </div>
                                </div>}

                                {caseNumberFlow == "true" && caseNumber !== "" && <div>
                                    <UserChatMessage message={caseNumber} displayName={appStore.displayUserName} />
                                    <div className={styles.chatMessageGpt}>
                                        <div className={styles.chatEmptyStateTitle}>
                                            <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                                            <div>
                                                Enter your question below
                                                {/* Please continue with your queries related to the case {caseNumber}. */}
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                            
                                {/* <div className={styles.chatMessageGpt}>
                                    <div className={styles.chatEmptyStateTitle}>
                                        <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                                        <div>
                                            <p> Enter your question below </p>
                                        </div>
                                    </div>
                                </div>
                             */}
                        </div>
                    ) : (
                        showFeedbackModal == false &&
                        showPrivacyNote == false && !showIntentButtonModal && !showDisclaimerModal &&  !showNotificationModal && (
                            <>
                                <div
                                    className={
                                        activeAnalysisPanelTab == undefined ? `${styles.chatMessageStream} ${styles.chatText}` : styles.chatMessageStreamOnclick
                                    }
                                >
                                    <div className={styles.chatMessageGpt}>
                                        <div className={styles.chatEmptyStateTitle}>
                                            <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                                            <div>
                                                <p style={{ fontSize: "20px" }}>
                                                    {" "}
                                                    Hello, <span style={{ fontWeight: "bold" }}>{appStore.displayUserName}</span> Welcome to the Honeywell IT
                                                    CoPilot.
                                                </p>
                                                {sbg !== "" ? (
                                                    <>
                                                        {" "}
                                                        <p>
                                                            To get the most of the tool be as specific as possible when describing what you and the customer
                                                            needs from the response.{" "}
                                                        </p>
                                                        <span>
                                                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>Note : </span>
                                                            <span>
                                                                Your selected Application Group is <span style={{ fontWeight: "bold" }}>{sbg} </span>
                                                            </span>
                                                            <p>Your sessions are recorded for quality and training purposes.</p>
                                                        </span>
                                                    </>
                                                ) : (
                                                    <p> Please select the Application Group from the settings to continue.</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                   
                                {sbg !== "" && <div className={styles.chatMessageGpt}>
                                    <div className={styles.chatEmptyStateTitle}>
                                        <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                                        {/* <div> */}
                                        <Stack horizontal horizontalAlign="space-between" style={{ width: "100%" }} >
                                            <StackItem className={styles.chatText}> Do you have an Incident Number ? </StackItem>
                                            <StackItem align="end">
                                                <Button style={{ marginRight: "20px" }} className={styles.buttonPrimary} onClick={() => handleYesNoClick("true")}>YES</Button>
                                                <Button className={styles.buttonPrimary} onClick={() => handleYesNoClick("false")}>NO</Button>
                                            </StackItem>
                                        </Stack>
                                    </div>
                                </div>}

                                {caseNumberFlow == "true" && <div className={styles.chatMessageGpt}>
                                    <div className={styles.chatEmptyStateTitle}>
                                        <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                                        <div>
                                            <p> Enter the incident number below  </p>
                                        </div>
                                    </div>
                                </div>}

                                {caseNumberFlow == "false" && <div className={styles.chatMessageGpt}>
                                    <div className={styles.chatEmptyStateTitle}>
                                        <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                                        <div>
                                            <p>  Enter your question below </p>
                                        </div>
                                    </div>
                                </div>}

                                {caseNumberFlow == "true" && caseNumber !== "" && <div>
                                    <UserChatMessage message={caseNumber} displayName={appStore.displayUserName} />
                                    <div className={styles.chatMessageGpt}>
                                        <div className={styles.chatEmptyStateTitle}>
                                            <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                                            <div>
                                                Enter your question below
                                                {/* Please continue with your queries related to the case {caseNumber}. */}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                    
                                        {/* // <div className={styles.chatMessageGpt}>
                                        //     <div className={styles.chatEmptyStateTitle}>
                                        //         <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                                        //         <div>
                                        //             <p> Enter your question below </p>
                                        //         </div>
                                        //     </div>
                                        // </div> */}
                                    
                                    {answers.map((answer, index) => (
                                        <div key={index}>
                                            <UserChatMessage message={answer[0]} displayName={appStore.displayUserName} />
                                            {/* {answer[1].answer.map((item, index) => ( */}
                                            <div className={styles.chatMessageGpt}>
                                                <CustomAnswer
                                                    key={0}
                                                    answer={null}
                                                    allAnswer={answer[1].answer}
                                                    isSelected={activeAnalysisPanelTab !== undefined}
                                                    onCitationClicked={c => onShowCitation(c, index)}
                                                    onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, 0)}
                                                    onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, 0)}
                                                    onFollowupQuestionClicked={q => makeApiRequest(q, "")}
                                                    showFollowupQuestions={useSuggestFollowupQuestions}
                                                    supportingContent={answer[1].data_points}
                                                />
                                            </div>
                                            {/* ))} */}
                                            {answer[1].data_points.length > 0 && (
                                                <SupportingCard supportingContent={answer[1].data_points} onCitationClicked={c => onShowCitation(c, index)} />
                                            )}
                                        </div>
                                    ))}
                                    {isLoading && (
                                        <>
                                            <UserChatMessage message={lastQuestionRef.current} displayName={appStore.displayUserName} />
                                            <div className={styles.chatMessageGptMinWidth}>
                                                <AnswerLoading message={lastQuestionRef.current} />
                                            </div>
                                        </>
                                    )}
                                    {error ? (
                                        <>
                                            <UserChatMessage message={lastQuestionRef.current} displayName={appStore.displayUserName} />
                                            <div className={styles.chatMessageGptMinWidth}>
                                                <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current, "")} />
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                                <div ref={chatMessageStreamEnd} />
                            </>
                        )
                    )}
                    {showFeedbackModal == false && sbg !== "" && showPrivacyNote == false && caseNumberFlow !== "" && !showIntentButtonModal && !showDisclaimerModal && !showNotificationModal && (
                        <div className={styles.chatInput}>
                            <QuestionInput
                                clearOnSend
                                placeholder={answers.length > 0 ? "Type a follow-up  question" : (caseNumberFlow == "true" ? "Type the incident number" : "Type a new question")}
                                disabled={isLoading}
                                onSend={question => handleInputQuestion(question)}
                                clearChat={clearChat}
                                clearChatDisabled={answers.length > 0 ? false : true}
                                caseNumberFlow={caseNumberFlow === "true"}
                            />
                            <Stack horizontal horizontalAlign="space-between" className={styles.info}>
                                <StackItem>
                                    <p>Generative AI is experimental, Info quality may vary</p>
                                </StackItem>
                                <StackItem align="end">
                                    <a href="https://www.honeywell.com/us/en/privacy-statement" target="_blank">
                                        <p style={{ color: "white" }}>Privacy Policy</p>
                                    </a>
                                </StackItem>
                            </Stack>
                        </div>
                    )}
                    {showFeedbackModal && showPrivacyNote == false && (
                        <Modal
                            isStartOver={startOver}
                            closeModal={(value: boolean, rating: number, comment: string, isBackToChat: boolean) =>
                                closeFeedbackModal(value, rating, comment, isBackToChat)
                            }
                        />
                    )}
                    {showPrivacyNote && <ModalPrivacy isStartOver={startOver} closeModal={(value: boolean) => closePrivacyModal(value)} />}

                    {showIntentButtonModal && showPrivacyNote == false && ( 
                        <ModalIntentButtons
                        closeModal={(value: boolean, intent: string ) =>
                            closeIntentModal(value, intent)
                        }
                    />
                    )}
                    {showDisclaimerModal && <ModalAIAgentDisclaimer isStartOver={startOver} closeModal={(value: boolean) => closeDisclaimerModal(value)} />}
                    {showNotificationModal && <ModalNotification isStartOver={startOver} closeModal={(value: boolean) => closeNotificationModal(value)} />}
                </div>
                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}

                <Panel
                    headerText="Configure answer generation"
                    isOpen={appStore.isConfigPanelOpen}
                    className={styles.chatSettings}
                    isBlocking={false}
                    onDismiss={() => appStore.setToggleConfigPanelOpen()}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => (
                        <DefaultButton
                            className={"dropdownSubmit"}
                            onClick={() => {
                                appStore.setToggleConfigPanelOpen(), console.log(selectedItem, "..selectedItem");
                                if (selectedItem) {
                                    onSbgChange(selectedItem);
                                }
                            }}
                        >
                            Submit
                        </DefaultButton>
                    )}
                    isFooterAtBottom={true}
                >
                    {Object.keys(userDetails).length > 0 ? (
                        <>
                            <span>
                                <DropdownSearch
                                    items={sbgOptions}
                                    // placeHolder will be displayed when no value or value is not found in items
                                    placeHolder="Select Application"
                                    className={"dropDownList"}
                                    // Always pass key to value prop
                                    value={sbg}
                                    noMatchText="No Match, Refine search."
                                    onChange={(item: any) => {
                                        console.log("DropdownSearch item", item);
                                        setSelectedItems(item);
                                    }}
                                    width={292}
                                    maxHeight={350}
                                    style={{ Function }}
                                />
                                {/* <Dropdown
                                    placeholder="Select Application"
                                    defaultSelectedKey={sbg}
                                    label="Applications Group:"
                                    options={sbgOptions}
                                    styles={dropdownStyles}
                                    onChange={onSbgChange}
                                /> */}
                            </span>

                            <br />
                            <br />
                        </>
                    ) : (
                        <div style={{ textAlign: "center" }}>
                            <Spinner />
                        </div>
                    )}
                </Panel>
            </div>
        </div>
    );
});

export default Chat;
