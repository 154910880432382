import { Stack, StackItem, Text, TextField } from "@fluentui/react";

import styles from "./Modal.module.css";
import { Fragment, useEffect, useState } from "react";
import { Feedback } from "../Feedback/Feedback";
import { Spinner } from "../Spinner";



interface Props {
  closeModal: (value: boolean, rating: number, comment: string, isBackToChat: boolean) => void;
  isStartOver: boolean;
}

export const Modal = ({ closeModal, isStartOver }: Props) => {
  const [isCloseDisabled, setIsCloseDisabled] = useState<boolean>(true);
  const [rating, setRating] = useState<number>(-1);
  const [comment, setComment] = useState<string>("");
  const [showSpinner, SetShowSpinner] = useState<boolean>(false);
  // Creat use state form rating and comment  
  const isCloseButtonDiabled = (value: boolean, rating: number, comment: string) => {
    console.log(rating,"rating")
    if(rating > 0 && comment.length > 0){
      setIsCloseDisabled(false);
    }
    else if(comment.length == 0) {
      setIsCloseDisabled(true);
    }
    setRating(rating);
    setComment(comment);
  }


  return (
    // <div className={styles.modale}>
    <div className={styles.modalDialog} >
      <div className={styles.modalHeader}>
        <h2>Feedback</h2>
        <p>Please provide the feedback for the previous conversation to proceed further.</p>
        {/* <a href="#" className={`${styles.btnClose} ${styles.closemodale}`} aria-hidden="true">&times;</a> */}
      </div>
      <div className={styles.modalBody}>
        <Feedback checkFeedbackValidated={isCloseButtonDiabled} />
      </div>
      <div className={styles.modalFooter}>

        {showSpinner ? <Spinner /> :
          <Stack horizontal horizontalAlign="space-evenly" style={{ width: "100%" }} >

            {isStartOver && <StackItem>
              <button onClick={() => { closeModal(false, rating, comment, true); SetShowSpinner(!showSpinner) }} className={styles.btnGoBack} id="btn_ingres" >BACK TO CHAT</button>
            </StackItem>}

            <StackItem>
              <button onClick={() => { closeModal(false, rating, comment, false); SetShowSpinner(!showSpinner) }} className={styles.btn} id="btn_ingresar" disabled={isCloseDisabled}>SUBMIT</button>
            </StackItem>
          </Stack>
        }
      </div>
    </div>
    // </div>
  );
};
