import SystemProperty from './SystemProperty';


export const config = {
    baseUrl: SystemProperty.getProperty('baseUrl'),
    userDetails:SystemProperty.getProperty('userDetails'),
    apigeeClientId:SystemProperty.getProperty('apigeeClientId'),
    apigeeClientSecret:SystemProperty.getProperty('apigeeClientSecret'),
    apigeeTokenUrl:SystemProperty.getProperty('apigeeTokenUrl'),
    backendUrl:SystemProperty.getProperty('backendUrl'),
    logoutUrl:SystemProperty.getProperty('logoutUrl'),
    // backendUrlPMT:SystemProperty.getProperty('backendUrl_PMT'),
    // backendUrlAERO:SystemProperty.getProperty('backendUrl_AERO'),
    // backendUrlHBT:SystemProperty.getProperty('backendUrl_HBT'),
    // backendUrlSPS:SystemProperty.getProperty('backendUrl_SPS'),
    redirectUrl: SystemProperty.getProperty('redirectUrl'),
    clientId:SystemProperty.getProperty('clientId'),
    citationLinkEdam : SystemProperty.getProperty('citationLinkEdam'),
    citationLinkUOP : SystemProperty.getProperty('citationLinkUOP'),
    citationLinkHPS : SystemProperty.getProperty('citationLinkHPS'),
    citationLinkAERO : SystemProperty.getProperty('citationLinkAERO'),
    citationLinkHBT : SystemProperty.getProperty('citationLinkHBT'),
    citationLinkSPS: SystemProperty.getProperty('citationLinkSPS')
};

