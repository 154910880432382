import { IIconStyles, IStackStyles, IStackTokens, Icon, Label, Stack, StackItem, Text, TextField } from "@fluentui/react";

import styles from "./Feedback.module.css";
import { Fragment, useEffect, useState, useRef } from "react";
import { FeedbackRadio } from "../FeedbackRadio/FeedbackRadio";

interface Props {
    checkFeedbackValidated: (value:boolean,rating:number,comment:string)=>void;

}
const richErrorIconStyles: Partial<IIconStyles> = { root: { color: 'red' } };
const richErrorStackStyles: Partial<IStackStyles> = { root: { height: 24 } };
const richErrorStackTokens: IStackTokens = { childrenGap: 8 };

export const Feedback = ({checkFeedbackValidated}:Props) => {
    const [showComments, setShowComments] = useState(false);
    const [rating,setRating]=useState(0);
    const [comment,setComment]=useState("");
    const erroNote =  useRef(false);

    const handleFeedBackChange = (e:any)=>{
        setRating(parseInt(e.currentTarget.value));
        setShowComments(true);
       // erroNote.current = true;
        // if(parseInt(e.currentTarget.value) > 5) {
        //     erroNote.current = true;
        // }
        // else {
        //     erroNote.current = false;
        // }
    }

    const handleCommentChange=(e:any)=>{
        setComment(e.currentTarget.value);
        // const validation = ValidatedFeedback(rating,comment);
         checkFeedbackValidated(false,rating,comment);
    }

    useEffect(()=>{
        if(comment !==""){
            checkFeedbackValidated(false,rating,comment);
        }else{
        checkFeedbackValidated(true,rating,comment)
    }
    },[rating,comment]);


    const validationMessage =(value:string)=> {
        if(comment === "" ) {
            return (
                <Stack verticalAlign="center" horizontal tokens={richErrorStackTokens}>
                <Icon iconName="Error" styles={richErrorIconStyles} />
                <Text variant="smallPlus">The Comment is Mandatory </Text>
            </Stack>
            )
        }
    }

    // const ValidatedFeedback=(rating:number,comment:string)=>{
    //     if(rating >0 && rating <=3 && comment !==""){
    //         return true
    //     }else if(rating >3){
    //         return true
    //     }
    //     return false;
    // }

    return (
        <Fragment>
            <FeedbackRadio />
           <div className={styles.feedback}>
                <label className={styles.angry}>
                    <input type="radio" value="1" name="feedback" onChange={handleFeedBackChange} />
                    <div>
                        <svg className={`${styles.eye} ${styles.left}`}>
                            <use href="#eye" xlinkHref="#eye"></use>
                        </svg>
                        <svg className={`${styles.eye} ${styles.right}`}>
                            <use href="#eye" xlinkHref="#eye">
                            </use>
                        </svg>
                        <svg className={styles.mouth}>
                            <use href="#mouth" xlinkHref="#mouth"></use>
                        </svg>
                    </div>
                </label>
                <label className={styles.sad}>
                    <input type="radio" value="2" name="feedback"  onChange={handleFeedBackChange}/>
                    <div>
                        <svg className={`${styles.eye} ${styles.left}`}>
                            <use href="#eye" xlinkHref="#eye"></use>
                        </svg>
                        <svg className={`${styles.eye} ${styles.right}`}>
                            <use href="#eye" xlinkHref="#eye">
                            </use>
                        </svg>
                        <svg className={styles.mouth}>
                            <use href="#mouth" xlinkHref="#mouth"></use>
                        </svg>
                    </div>
                </label>
                <label className={styles.ok}>
                    <input type="radio" value="3" name="feedback" onChange={handleFeedBackChange}/>
                    <div></div>
                </label>
                <label className={styles.good}>
                    <input type="radio" value="4" name="feedback" onChange={handleFeedBackChange}/>
                    <div>
                        <svg className={`${styles.eye} ${styles.left}`}>
                            <use href="#eye" xlinkHref="#eye"></use>
                        </svg>
                        <svg className={`${styles.eye} ${styles.right}`}>
                            <use href="#eye" xlinkHref="#eye">
                            </use>
                        </svg>
                        <svg className={styles.mouth}>
                            <use href="#mouth" xlinkHref="#mouth"></use>
                        </svg>
                    </div>
                </label>
                <label className={styles.happy}>
                    <input type="radio" value="5" name="feedback"  onChange={handleFeedBackChange}/>
                    <div>
                        <svg className={`${styles.eye} ${styles.left}`}>
                            <use href="#eye" xlinkHref="#eye"></use>
                        </svg>
                        <svg className={`${styles.eye} ${styles.right}`}>
                            <use href="#eye" xlinkHref="#eye">
                            </use>
                        </svg>
                    </div>
                </label>
            </div>
        <Stack style={{ flexDirection: "row" }}>
        <StackItem>
          <Label style={{ textAlign: "left" ,verticalAlign:"middle",fontWeight:400}}>Please provide any additional comments or feedback:</Label>
        </StackItem>
        </Stack>
           {showComments && 
            <TextField  onGetErrorMessage={validationMessage}  multiline rows={3} onChange={handleCommentChange}/>}
            
    
            {/* <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                    <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                    <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
            </svg> */}
        </Fragment>
    );
};
