export const systemProperties: {[x: string]: string } = {
    //Client Secret Value Prod= "cLi8Q~KWy~Fu1HeKOT6hWplgcMvEy0YXggSSPcUF"
    //Client Secret Value Non-Prod ="Lgw8Q~.6jsAvHkptbu3j4IVrUjvBNcW0e4wkrdg_"

    // PROD
    'baseUrl_prod': 'https://itcopilot.honeywell.com',
    'userDetails_prod':'https://itcopilot.api.honeywell.com/v1/cit-azure-GenAI-TechPub/UserDetails',
    'apigeeClientId_prod':'vLuXK5NhotEuST8Zk3Zpujwmi1xB3X22',
    'apigeeClientSecret_prod':'SzZ3ohtOpMBWmH9X',
    'apigeeTokenUrl_prod':'https://itcopilot.api.honeywell.com/v2/oauth/accesstoken',
    'backendUrl_prod':'https://itcopilot.api.honeywell.com', 
    'clientId_prod':'fbf31fad-39e4-402e-9a3d-fd86dfaf21ec',
    'redirectUrl_prod': 'https://login.microsoftonline.com/96ece526-9c7d-48b0-8daf-8b93c90a5d18/oauth2/v2.0/authorize?client_id=fbf31fad-39e4-402e-9a3d-fd86dfaf21ec&response_type=code&redirect_uri=https://itcopilot.honeywell.com&scope=openid offline_access User.Read&response_mode=fragment&code_challenge=dizq1u-okh9Tm1E5oTtR7q50yEXFGFewbfLD4vpP95Q&code_challenge_method=S256',
    'logoutUrl_prod': 'https://login.microsoftonline.com/96ece526-9c7d-48b0-8daf-8b93c90a5d18/oauth2/v2.0/logout?client_id=fbf31fad-39e4-402e-9a3d-fd86dfaf21ec&logout_hint=',


    
    // QA
    'baseUrl_qa': 'https://itsmcopilot-qa.honeywell.com',
    'userDetails_qa':'https://it.api-dev.honeywell.com/v1/cit-azure-GenAI-TechPub/UserDetails',
    'apigeeClientId_qa':'vLuXK5NhotEuST8Zk3Zpujwmi1xB3X22',
    'apigeeClientSecret_qa':'SzZ3ohtOpMBWmH9X',
    'apigeeTokenUrl_qa':'https://it.api-dev.honeywell.com/v2/oauth/accesstoken',
    'backendUrl_qa':'https://itcopilot-qa.api.honeywell.com',
    'clientId_qa':'55fff723-345a-4744-91e8-290f09bee7af',
    'redirectUrl_qa': 'https://login.microsoftonline.com/96ece526-9c7d-48b0-8daf-8b93c90a5d18/oauth2/v2.0/authorize?client_id=55fff723-345a-4744-91e8-290f09bee7af&response_type=code&redirect_uri=https://itsmcopilot-qa.honeywell.com&scope=openid offline_access User.Read&response_mode=fragment&code_challenge=dizq1u-okh9Tm1E5oTtR7q50yEXFGFewbfLD4vpP95Q&code_challenge_method=S256',
    'logoutUrl_qa': 'https://login.microsoftonline.com/96ece526-9c7d-48b0-8daf-8b93c90a5d18/oauth2/v2.0/logout?client_id=55fff723-345a-4744-91e8-290f09bee7af&logout_hint=',

    // DEV
    'baseUrl_dev': 'https://corp-openai-itcopilot-ui-np.azurewebsites.net',
    'userDetails_dev':'https://it.api-dev.honeywell.com/v1/cit-azure-GenAI-TechPub/UserDetails',
    'apigeeClientId_dev':'vLuXK5NhotEuST8Zk3Zpujwmi1xB3X22',
    'apigeeClientSecret_dev':'SzZ3ohtOpMBWmH9X',
    'apigeeTokenUrl_dev':'https://it.api-dev.honeywell.com/v2/oauth/accesstoken',
    'backendUrl_dev':'https://corp-openai-itcopilot-be-api.azurewebsites.net',
    'clientId_dev':'55fff723-345a-4744-91e8-290f09bee7af',
    'redirectUrl_dev': 'https://login.microsoftonline.com/96ece526-9c7d-48b0-8daf-8b93c90a5d18/oauth2/v2.0/authorize?client_id=55fff723-345a-4744-91e8-290f09bee7af&response_type=code&redirect_uri=https://corp-openai-itcopilot-ui-np.azurewebsites.net&scope=openid offline_access User.Read&response_mode=fragment&code_challenge=dizq1u-okh9Tm1E5oTtR7q50yEXFGFewbfLD4vpP95Q&code_challenge_method=S256',
    'logoutUrl_dev': 'https://login.microsoftonline.com/96ece526-9c7d-48b0-8daf-8b93c90a5d18/oauth2/v2.0/logout?client_id=55fff723-345a-4744-91e8-290f09bee7af&logout_hint=',

    // local
    'baseUrl_local': 'http://localhost:5173',
    'userDetails_local':'https://it.api-dev.honeywell.com/v1/cit-azure-GenAI-TechPub/UserDetails',
    'apigeeClientId_local':'vLuXK5NhotEuST8Zk3Zpujwmi1xB3X22',
    'apigeeClientSecret_local':'SzZ3ohtOpMBWmH9X',
    'apigeeTokenUrl_local':'https://it.api-dev.honeywell.com/v2/oauth/accesstoken',
    'backendUrl_local':'http://172.19.135.6:8000',
    'clientId_local':'39d0e3fb-ddbe-464b-ae2f-e373151d6ec3',
    'redirectUrl_local':  'https://login.microsoftonline.com/96ece526-9c7d-48b0-8daf-8b93c90a5d18/oauth2/v2.0/authorize?client_id=39d0e3fb-ddbe-464b-ae2f-e373151d6ec3&response_type=code&redirect_uri=http://localhost:5173&scope=openid offline_access User.Read&response_mode=fragment&code_challenge=dizq1u-okh9Tm1E5oTtR7q50yEXFGFewbfLD4vpP95Q&code_challenge_method=S256',
    'logoutUrl_local': 'https://login.microsoftonline.com/96ece526-9c7d-48b0-8daf-8b93c90a5d18/oauth2/v2.0/logout?client_id=39d0e3fb-ddbe-464b-ae2f-e373151d6ec3&logout_hint='
};
