import { useState } from "react";
import { Stack, StackItem, TextField } from "@fluentui/react";
import { Send28Filled } from "@fluentui/react-icons";
import { useAppStore } from "../..";

import styles from "./QuestionInput.module.css";

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    clearChat:()=>void;
    clearChatDisabled:boolean;
    caseNumberFlow:boolean;
}


export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend ,clearChat,clearChatDisabled, caseNumberFlow}: Props) => {
    const appStore = useAppStore();
    const [question, setQuestion] = useState<string>("");
    const [numInput, setNumInput] = useState<boolean>(caseNumberFlow);
   
    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        if (numInput) {
            const regex = /^INC\d{10}$/;
            if (!regex.test(question?.trim())) {
                appStore.openAlert("Provided incident number is invalid, Please enter incident number in this format, INC0123456789");
                return;
            }
        }
        onSend(question);
        setNumInput(false);

        if (clearOnSend) {
            setQuestion("");
        }
        
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <Stack horizontal horizontalAlign="space-between" style={{ width: "100%" }}>
            <StackItem className={styles.questionInputContainer}>
                <TextField
                    className={styles.questionInputTextArea}
                    placeholder={placeholder}
                    multiline
                    resizable={false}
                    borderless
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                    style={{ fontSize: "16px" }}
                />
                <div className={styles.questionInputButtonsContainer}>
                    {/* <div style={{width:'0.1rem',height:'2.5rem',opacity:'0.6',backgroundColor: '#1275b7'}}></div> */}
                    <div
                        className={`${styles.questionInputSendButton} ${sendQuestionDisabled ? styles.questionInputSendButtonDisabled : ""}`}
                        aria-label="Ask question button"
                        onClick={sendQuestion}
                    >
                        <Send28Filled primaryFill="#1275b7" />
                    </div>
                </div>
            </StackItem>
            <StackItem className={styles.feedbackContainer} align="end">
                {clearChatDisabled ? (
                    <a className={styles.buttonPrimaryDisabled}>Feedback / Start Over</a>
                ) : (
                    <a className={styles.buttonPrimary} onClick={clearChat}>
                        Feedback / Start Over
                    </a>
                )}
            </StackItem>
        </Stack>
    );
};
