import { useEffect, useMemo, useState } from "react";
import { Stack, IconButton, StackItem, Rating } from "@fluentui/react";
import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    AccordionToggleEventHandler,
} from "@fluentui/react-components";
import DOMPurify from "dompurify";
import { parseSupportingContentItem } from "../SupportingContent/SupportingContentParser";
import {
    ThumbLike20Regular, LightbulbRegular, Lightbulb20Regular, ThumbLike16Regular, ThumbDislike16Regular, FluentIconsProps, iconFilledClassName,
    iconRegularClassName,
    ThumbLike16Filled,
    ThumbDislike16Filled,
    PersonCircle24Filled,
    Bot24Filled,
} from '@fluentui/react-icons';

import styles from "./Answer.module.css";

import { AskResponse, getCitationFilePath, answerRequest } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { makeStyles } from '@fluentui/react-components';
import { CopyButton } from "../CopyButton";
import { observer } from "mobx-react";
import { useAppStore } from "../..";
import botLogo from '../../assets/H_vector.svg';
import Typewriter from 'typewriter-effect';
import Typed from 'react-typed';

interface Props {
    answer: answerRequest | null;
    allAnswer: any;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    supportingContent: string[];
}

const iconStyleProps: FluentIconsProps = {
    primaryFill: 'purple',
    className: 'iconClass',
};



export const CustomAnswer = observer(({
    answer,
    allAnswer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    supportingContent

}: Props) => {
    const appStore = useAppStore();
    const start = "Searched for:<br>";
    const end = "<br>Prompt:";
    const str = "";
    const [openItems, setOpenItems] = useState(["0"]);
    const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
        setOpenItems(data.openItems);
      };
    let citationArray: any = [];
    let index: any = 0;
    const string_between_strings = (startStr: any, endStr: any, str: string) => {
        let pos = str.indexOf(startStr) + startStr.length;
        return str.substring(pos, str.indexOf(endStr, pos));
    };
    const sanitizedThoughts = DOMPurify.sanitize(string_between_strings(start, end, str));
    const [ratingAnswer, setRating] = useState(0);
    const [likeClicked, setLikeClicked] = useState(false);
    const [dislikeClicked, setDislikeClicked] = useState(false);
    const [citationEmpty, setCitationEmpty] = useState(true);
    const [typed, setTyped] = useState(false);

    // const onLikeClicked = () => {
    //     //    const val = likeClicked == true?false :true;
    //     setDislikeClicked(false)
    //     setLikeClicked(!likeClicked);
    // }
    // const onDislikeClicked = () => {
    //     // const val = dislikeClicked == true?false :true;
    //     setLikeClicked(false);
    //     setDislikeClicked(!dislikeClicked);
    // }

    const removeHTMLtagsAnswer = (allAnswer: any) => {
        let result = '';
        allAnswer.map((answerItem: any) => {
            const parsedAnswer = useMemo(() => parseAnswerToHtml(answerItem.content, onCitationClicked), [answerItem]);
            const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
            const parsedAnswerData = sanitizedAnswerHtml.replaceAll(/<[^>]+>/g, '');
            result += parsedAnswerData.replaceAll(/1|2|3|4|5|6|7|8|9|0/g, '');
        });
        return result;
    }
    const answerData = (answerItem: any, index: number) => {
        const parsedAnswer = useMemo(() => parseAnswerToHtml(answerItem.content, onCitationClicked), [answerItem]);
        const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
        return sanitizedAnswerHtml;
    }
    useEffect(() => {
         console.log("sdcsd",ratingAnswer);
         setRating(ratingAnswer);
         appStore.setPreviousQueryFeedback(ratingAnswer);
        // if (dislikeClicked == true) {
        //     setLikeClicked(false)
        //     appStore.setPreviousQueryFeedback(0)
        // } else if (likeClicked == true) {
        //     setDislikeClicked(false)
        //     appStore.setPreviousQueryFeedback(1)
        // } else {
        //     appStore.setPreviousQueryFeedback(-1)
        // }


        // if(dislikeClicked == true ){
        //     setDislikeClicked(!likeClicked);
        //      setLikeClicked(!likeClicked);
        // }

    }, [likeClicked, dislikeClicked])
    const rateChanged = (ev:any,value: any) => {
        console.log(value, "..value");
        setRating(value);
        appStore.setPreviousQueryFeedback(value);
    }
    const parsedAnswerData = (answerItem: any, index: number) => {
        //  console.log(answerItem,"..answerItem")
        const parsedAnswer = useMemo(() => parseAnswerToHtml(answerItem.content, onCitationClicked), [answerItem]);
        const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
        return <> {!!parsedAnswer.citations.length && (
            <Stack horizontal wrap tokens={{ childrenGap: 5 }} key={index}>
                {!citationEmpty && <span className={styles.citationLearnMore}>Citations:</span>}
                {supportingContent.map((x) => {
                    const parsed = parseSupportingContentItem(x);
                    const path = parsed.docUrl;
                    const citation = parsedAnswer.citations.some(str => {
                        if (str.includes(parsed.title) && !citationArray.includes(str)) {
                            citationArray.push(str);
                            if (citationEmpty) {
                                setCitationEmpty(false);
                            }
                            return true;
                        }
                        else {
                            return false;
                        }
                    });

                    if (citation) {
                        return (
                            <a key={index} className={styles.citation} title={parsed.title} onClick={() => onCitationClicked(`${path}:${parsed.title}`)}>
                                {`${++index}. ${parsed.title}`}
                            </a>
                        );
                    }
                })}

            </Stack>
        )}</>
    }
    const parsedItemData = (answerItem: any, index: number) => {
        const parsedAnswer = useMemo(() => parseAnswerToHtml(answerItem.content, onCitationClicked), [answerItem]);
        const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

        return <>{!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
            <Stack.Item key={index}>
                <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                    <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                    {parsedAnswer.followupQuestions.map((x, i) => {
                        return (
                            <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                {`${x}`}
                            </a>
                        );
                    })}
                </Stack>
            </Stack.Item>
        )}</>
    }
    const onComplete = () => {
        // console.log("completed");
        setTyped(true);
    }
    return (
        <Stack horizontal horizontalAlign="space-between" style={{ width: "100%" }}>
            <StackItem style={{ width: "100%" }}>
                <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between" style={{ width: "100%" }}>
                    {/* <Stack.Item>
                <span style={{ fontSize: "16px" }}>Searched for:</span>
                <div className={styles.searchedFor} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
            </Stack.Item> */}

                    <Stack.Item>
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack.Item>
                                <div style={{ backgroundImage: `url(${botLogo})` }} className={styles.botLogo}></div>
                            </Stack.Item>
                            <Stack.Item grow>
                                <p className={styles.TechGPT}>ItCopilot:</p>
                                <div className={styles.answerText}>
                                <Accordion openItems={openItems}
                                onToggle={handleToggle}
  className="accordionAnswer" collapsible style={{borderWidth:1,borderColor:"#ddd",borderStyle:"solid",borderRadius:5}}>
                                        {

                                            allAnswer.map((answerItem: any, index: number) => {
                                                const sanitizedAnswerHtml = answerData(answerItem, index);
                                                return (sanitizedAnswerHtml ? <>
                                                    <AccordionItem style={{borderBottomWidth:1,borderBottomColor:"#ddd",borderBottomStyle:"solid"}} value={String(index)}>
                                                        <AccordionHeader><span className="accordionHeaderDiv">{answerItem.title}</span></AccordionHeader>
                                                        <AccordionPanel>
                                                            <p dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></p>
                                                            {/* <Typed
                                                                typedRef={(typedInstance: any) => { setTyped(typedInstance) }}
                                                                strings={[sanitizedAnswerHtml]}
                                                                typeSpeed={1}
                                                            /> */}
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                </> : null);
                                            })

                                        }
                                    </Accordion>
                                </div>
                                {
                                    allAnswer.map((answerItem: any, index: any) =>
                                        parsedAnswerData(answerItem, index)
                                    )
                                }
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    {/* <Stack.Item  >
                <Stack horizontal horizontalAlign="space-between" >
                    <Stack.Item align="start" >
                        <CopyButton content={parsedAnswer.answertext} />
                    </Stack.Item  >
                    <Stack.Item align="end">
                        <Stack horizontal horizontalAlign="space-between" >
                            <div>
                                <button onClick={() => onLikeClicked()} type="button" className={`ms-Button ms-Button--icon ${styles.root128}`} data-is-focusable="true" title="Liked the response" aria-label="Liked the response" style={{ color: "black" }}>
                                    <span className="ms-Button-flexContainer flexContainer-129" data-automationid="splitbuttonprimary">
                                        <i data-icon-name="Lightbulb" aria-hidden="true" className="ms-Icon root-105 css-137 ms-Button-icon icon-131" >
                                            {likeClicked ? <ThumbLike16Filled /> : <ThumbLike16Regular />}
                                        </i>
                                    </span>
                                </button>
                                <button onClick={() => onDislikeClicked()} type="button" className={`ms-Button ms-Button--icon ${styles.root128}`} data-is-focusable="true" title="Disliked the response" aria-label="Disliked the response" style={{ color: "black" }}>
                                    <span className="ms-Button-flexContainer flexContainer-129" data-automationid="splitbuttonprimary">
                                        <i data-icon-name="Lightbulb" aria-hidden="true" className="ms-Icon root-105 css-137 ms-Button-icon icon-131" >
                                            {dislikeClicked ? <ThumbDislike16Filled /> : <ThumbDislike16Regular />}
                                        </i>
                                    </span>
                                </button>
                       
                            </div>
                        </Stack>
                    </Stack.Item  >
                </Stack>
            </Stack.Item> */}
                    <Stack.Item align="end" >
                        <span className={styles.warningText}><b style={{color:'indianred'}}>Please Note:</b> Content generated by AI may not be accurate. Users must exercise their own judgment and verify the information before use.</span>
                    </Stack.Item>
                    {/* <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        /> */}

                    {/* {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                    {`${++i}. ${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )} */}
                    {allAnswer.map((answerItem: any, index: number) =>
                        parsedItemData(answerItem, index)
                    )}

                    <Stack.Item align="end">
                    </Stack.Item>
                </Stack>
            </StackItem>
            <StackItem style={{ height: "100%" }} align="end" >
                <Stack verticalAlign="space-between">
                    <StackItem className={styles.copyContainerAuto} style={{ borderTopRightRadius: "8px", color: "#1275b7" }}>
                        <CopyButton content={removeHTMLtagsAnswer(allAnswer)} />
                    </StackItem>
                    <Stack.Item align="end" className={styles.copyContainerAuto} style={{ borderBottomRightRadius: "8px" }}>
                        <Stack horizontal>

                            {/* <AnswerIcon /> */}
                            <StackItem style={{ width: "auto" }}>
                                <Rating
                                    className="customRating"
                                    rating={ratingAnswer}
                                    allowZeroStars={true}
                                    styles={{
                                        ratingFocusZone: {
                                            color: '#FF9529 !important',
                                            selectors: {
                                                ':hover': {
                                                    color: '#FF9529 !important'
                                                }
                                            }
                                        },
                                        ratingStarBack: {
                                            color: '#FF9529 !important',
                                            selectors: {
                                                ':hover': {
                                                    color: '#FF9529 !important'
                                                }
                                            }
                                        },
                                        ratingStarFront: {
                                            color: '#FF9529 !important',
                                            selectors: {
                                                ':hover': {
                                                    color: '#FF9529 !important'
                                                }
                                            }
                                        }
                                    }}
                                    onChange={rateChanged}
                                />
                                {/* <button onClick={() => onLikeClicked()} type="button" className={`ms-Button ms-Button--icon ${styles.root128}`} data-is-focusable="true" title="Liked the response" aria-label="Liked the response" style={{ color: "green" }}>
                                    <span className="ms-Button-flexContainer flexContainer-129" data-automationid="splitbuttonprimary">
                                        <i data-icon-name="Lightbulb" aria-hidden="true" className="ms-Icon root-105 css-137 ms-Button-icon icon-131" >
                                            {likeClicked ? <ThumbLike16Filled /> : <ThumbLike16Regular />}
                                        </i>
                                    </span>
                                </button> */}
                            </StackItem>
                            {/* <StackItem>
                                <button onClick={() => onDislikeClicked()} type="button" className={`ms-Button ms-Button--icon ${styles.root128}`} data-is-focusable="true" title="Disliked the response" aria-label="Disliked the response" style={{ color: "red" }}>
                                    <span className="ms-Button-flexContainer flexContainer-129" data-automationid="splitbuttonprimary">
                                        <i data-icon-name="Lightbulb" aria-hidden="true" className="ms-Icon root-105 css-137 ms-Button-icon icon-131" >
                                            {dislikeClicked ? <ThumbDislike16Filled /> : <ThumbDislike16Regular />}
                                        </i>
                                    </span>
                                </button>
                                </StackItem> */}
                            {/* <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        /> */}

                        </Stack>
                    </Stack.Item  >
                </Stack>

            </StackItem>
        </Stack>
    );
});
