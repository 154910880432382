import { config } from "../config";

export const isAccessTokenNeedsToBeRefreshed = () => {
    let startTime: any = localStorage.getItem("startTime");
        console.log("Start Time 1", startTime);
        startTime = new Date(JSON.parse(startTime));
        console.log("Start Time 2", startTime);
        if (startTime !== null && startTime !== undefined) {
            const diffMilliseconds = Math.abs(new Date().getTime() - startTime.getTime());
            const diff = Math.floor(diffMilliseconds / 1000);
            console.log("Diff 1", diff);
            const expTime: any = localStorage.getItem("expirationTime");
            console.log("Diff 2", parseInt(expTime) - diff);
            if (expTime != null && parseInt(expTime) - diff < 1000) {
                console.log("Token Expired");
                return true;
            }
        }
        console.log("Token Not Expired");
        return false;
};

export const getAccesToken = () => {
    return localStorage.getItem("Token");
};

export async function generateCodeVerifier() {
    const array = new Uint8Array(32);
    crypto.getRandomValues(array);
    return Array.from(array)
        .map(b => b.toString(16).padStart(2, "0"))
        .join("");
}

export async function generateCodeChallenge(codeVerifier: any) {
    // base64Url-encoder(SHA256(ASCII(code_verifier)))
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await crypto.subtle.digest("SHA-256", data);

    const base64Url = (arrayBuffer: any) => {
        const base64 = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
        return base64.replace("+", "-").replace("/", "_").replace(/=+$/, "");
    };

    return base64Url(digest);
}

export async function getRedirectUrl() {
    const codeVerifier = "GEUktnIkH29Gg0wK8G1LvlGRYqVOgf8mHMhMmKYr-6Y" //await generateCodeVerifier();
    const codeChallenge = "dizq1u-okh9Tm1E5oTtR7q50yEXFGFewbfLD4vpP95Q"//await generateCodeChallenge(codeVerifier);
    sessionStorage.setItem("code_verifier", codeVerifier);
    return config.redirectUrl;
}
