import styles from "./UserChatMessage.module.css";
import user from '../../assets/user.png';
import { Stack } from "@fluentui/react";

interface Props {
    message: string;
    displayName:string;
}

export const UserChatMessage = ({ message,displayName }: Props) => {
    function getInitials(): import("react").ReactNode {
        const names =displayName.split(",");
        if(names.length === 2) {
            return  (names[0].trim().charAt(0) + names[1].trim().charAt(0)); 
        }
        else {
            return names[0].trim().charAt(0);
        }
    }

    return (
        <div className={styles.container}>
              <Stack horizontal  className={styles.message} >
                    <Stack.Item >
                       <div  className={styles.avatar}> <div  className={styles.avatarText} >{getInitials()}</div></div>
                    {/* <div  style={{ backgroundImage: `url(${user})` }} className={styles.user}></div> */}
                    </Stack.Item>
                    <Stack.Item className="chatListStackItem">
                    <div className={styles.TechGPT}>{displayName}</div>
                    <div  className={styles.chatText}>
                    {message} 
                    </div>
                    </Stack.Item>
                   
                </Stack>
        </div>
    );
};
