import { Outlet, NavLink, Link } from "react-router-dom";

import github from "../../assets/github.svg";

import styles from "./Layout.module.css";
import headerLogo from '../../assets/honeywell-logo.png';
import { SettingsButton } from "../../components/SettingsButton";
import { observer } from "mobx-react";
import { useAppStore } from "../..";

const Layout =observer(() => {
    const appStore = useAppStore();
   const  handleSettingClicked=()=>{
appStore.setToggleConfigPanelOpen();
   }
   
    return (
        <div className={styles.layout}>
            {/* <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <div  style={{ backgroundImage: `url(${headerLogo})` }} className={styles.headerLogo}></div>
                        <div className={styles.headerTitle}>IT CoPilot </div>
                    </Link>
                    {location.href.includes("techgpt-qa") && <span  className={styles.qaEnvironment}> Non-Prod - Enviroment </span>}
                    <SettingsButton className={styles.commandButton} onClick={() => handleSettingClicked()} />
                </div>
            </header> */}

            <Outlet />
        </div>
    );
});

export default Layout;
