import { Text, TextField } from "@fluentui/react";

import styles from "./Login.module.css";
import { Fragment, useEffect, useState } from "react";
import { getAccessToken, createSession, getUserDetails } from "../../api";
import { generateCodeChallenge, generateCodeVerifier, getRedirectUrl } from "../../api/apiUtils";
import { observer } from 'mobx-react';
import { useAppStore } from "../..";
import AppStore from "../../store/AppStore";
import backGroundImg from "../../assets/hon-sustainability.png";

interface Props {
    addUserName: (user: string) => void;
}


export const Login = observer(({ addUserName }: Props) => {
    const appStore = useAppStore();
    useEffect(() => {
        (async () => {
            // techGPT // const redirectURL= "https://login.microsoftonline.com/96ece526-9c7d-48b0-8daf-8b93c90a5d18/oauth2/v2.0/authorize?client_id=39d0e3fb-ddbe-464b-ae2f-e373151d6ec3&response_type=code&redirect_uri=https://techgpt-qa.honeywell.com&scope=openid offline_access&response_mode=fragment";
            // const redirectURL= "https://login.microsoftonline.com/96ece526-9c7d-48b0-8daf-8b93c90a5d18/oauth2/v2.0/authorize?client_id=39d0e3fb-ddbe-464b-ae2f-e373151d6ec3&response_type=token&redirect_uri=http://localhost:5173&scope=user.read&response_mode=fragment";
            if (location.href.split("#code=").length > 1) {
                var myHeaders = new Headers();

                var code = location.href.split("#code=")[1].split("&session_state=")[0];
                const codeVerifier = sessionStorage.getItem("code_verifier");
                if (codeVerifier !== null) {
                    var response = await getAccessToken(code, codeVerifier);
                    const token = response.access_token;
                    const refreshToekn = response.refresh_token;
                    const expirationTime = response.expires_in;
                    const previousToken = localStorage.getItem("Token");
                    if (previousToken === null || previousToken !== token) {
                        localStorage.setItem("Token", token);
                        localStorage.setItem("refreshToekn", refreshToekn)
                        localStorage.setItem("expirationTime", expirationTime);
                        localStorage.setItem("startTime", JSON.stringify(new Date()));
                    }
                    myHeaders.append("Authorization", "Bearer " + token);
                    myHeaders.append("Strict-Transport-Security", "max-age=64800 ; includeSubDomains");
                    myHeaders.append("X-XSS-Protection", "1; mode=block");
                    window.history.replaceState(null, "null", window.location.pathname);



                    try {
                        const user = await callAccessToken(myHeaders);
                        if (user != null) {
                          var userDetails = await getUserDetails(user);
                          appStore.setUserDetails(userDetails);
                           var sessionDeatils= await createSession(user);
                           if(sessionDeatils?.bannerText?.length > 0){    
                                appStore.setBannerText(sessionDeatils.bannerText);
                            }
                           if(sessionDeatils?.conversation?.length > 0 && sessionDeatils.previousRatingProvided==false ){
                            appStore.setPreviousSessionRatingProvided(sessionDeatils.previousRatingProvided)
                           }else{
                            appStore.setPreviousSessionRatingProvided(true)
                           }
                         
                            addUserName(user);  
                            appStore.setUserName(user);
                            
                        }


                    } catch (error) {
                        //  window.location.replace(await getRedirectUrl())
                        //  window.location.href = await getRedirectUrl()
                    }
                }
            } else {
                const reUrl = await getRedirectUrl();
                window.location.replace(reUrl);
                window.location.href = reUrl;
            }
        })();
    }, []);

    async function callAccessToken(myHeaders: any) {
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow" as RequestRedirect,
            referrer: location.origin,
            referrerPolicy: "strict-origin-when-cross-origin" as ReferrerPolicy
        };
        const response = await fetch("https://graph.microsoft.com/v1.0/me", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    //  window.location.replace(await getRedirectUrl())
                    //  window.location.href = getRedirectUrl()
                } else {
                    // addUserName(result.mail.toString());
                    // appStore.setUserName(result.mail.toString());
                    appStore.setDisplayUserName(result.displayName)
                   // return result.mail.toString();
                    return result.userPrincipalName.toString();

                    // setBrowserFingerprint(result.mail);
                }
                // loadSelection(result.mail);
            })
            .catch(error => {
                throw new Error("unauth");
                return error;
            });
return response;
        // const userData = await getUserDetails(response);
        // appStore.setUserDetails(userData);

    }
    return <>
     <div id="backgroundImageDisplay" style={{ backgroundImage: `url(${backGroundImg})`, verticalAlign:"middle" }} className={styles.chatContainer}>
            <div style={{width:"80%"}}>
                <div className={styles.headerLeft} id="honeywellBrnadMessage">
                    <span className={styles.textRed}>The</span>
                    <span className={styles.textRed}>Future</span>
                    <span className={styles.textWhite}>Is</span>
                    <span className={styles.textWhite}>What</span>
                    <span className={styles.textWhite}>We</span>
                    <span className={styles.textWhite}>Make It</span>
                </div>
                <div className={styles.headerRight} id="honeywellBrnadMessage">
                    <div className={styles.ldsSpinner}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                <div className={styles.clearfix}></div>
            </div>
        </div>
    </>;
});


