import { Text } from "@fluentui/react";
import { Copy24Regular } from "@fluentui/react-icons";

import styles from "./CopyButton.module.css";
import { useEffect, useState } from "react";

interface Props {
    content: string;
    // onClick: () => void;
}

export const CopyButton = ({content}:Props) => {
  const [clicked,setClicked]=useState(false)
   const  handleOnClick =async()=>{
    await navigator.clipboard.writeText(content);
    setClicked(true);
   }
   useEffect(() => {
    const timer = setTimeout(() => {
        setClicked(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [clicked]);
  
    return (
        <div className={`${styles.container}`} onClick={handleOnClick}>
            <Copy24Regular />
            <Text className={styles.settings}>{clicked == true ?"Copied":"Copy"}</Text>
        </div>
    );
};
