import { DefaultButton, DirectionalHint, FocusZone, SearchBox } from "@fluentui/react";
import React from "react";

const DropdownSearch = (props:any) => {
    console.log("DropdownSearch(props)", props);
    let [items, setItems] = React.useState(props.items);
    const filteredItems = props.items.filter(({ key }:any) =>
      key.toLowerCase().includes(props.value.toLowerCase())
    );
    let [value, setValue] = React.useState(
      props.value
        ? filteredItems.length === 0
          ? props.placeHolder
          : filteredItems[0].text
        : props.placeHolder
    );
  
    const onRenderMenuList = (itemProps:any, defaultRender:any) => {
      console.log(itemProps);
      return (
        <FocusZone>
          <SearchBox autoComplete="off" onChange={onSearch} />
          {itemProps.totalItemCount > 0 &&
            itemProps.items[0].key != "NOMATCH" &&
          defaultRender(itemProps)}
          {itemProps.totalItemCount == 1 &&
            itemProps.items[0].key == "NOMATCH" && (
              <p>
                {props.noMatchText
                  ? props.noMatchText
                  : "No Match, Refine search."}
              </p>
            )}
        </FocusZone>
      );
    };
  
    const onSearch = (e:any, v:any) => {
     const newItems = props.items.filter(({ text }:any) =>
        text.toLowerCase().includes(v.toLowerCase())
      );
      if (newItems.length == 0) {
        setItems([
          {
            text: "No Match",
            key: "NOMATCH"
          }
        ]);
      } else {
        setItems(newItems);
      }
    };
  
    const menuProps = {
      items: items,
      onItemClick: (e:any, i:any) => {
        setItems(props.items);
        setValue(i.text);
        const selItems = props.items.filter(({ text }:any) =>
          text.toLowerCase().includes(i.text.toLowerCase())
        );
        props.onChange(selItems[0]);
      },
      onRenderMenuList: onRenderMenuList,
      styles: { container: { width: props.width, maxHeight: props.maxHeight } },
      directionalHint: DirectionalHint.bottomAutoEdge,
      directionalHintFixed: true
    };
  
    return (
      <><div style={{padding: "10px 0"}}>Applications Group:</div>
      <DefaultButton
      className="searchButton"
        styles={{
          root: {
            width: props.width,
            textAlign: "left",
            padding:0
          }
        }}
        menuIconProps={{ iconName: "ChevronDownSmall" }}
        text={value}
        menuProps={menuProps}
        onAfterMenuDismiss={() => {
          setItems(props.items);
        }}
      /></>
    );
  };
  export default DropdownSearch;