import { Stack, StackItem, Text, TextField } from "@fluentui/react";
import styles from "./Modal.module.css";
import { useState } from "react";
import { Spinner } from "../Spinner";

interface Props {
  closeModal: (value: boolean) => void;
  isStartOver: boolean;
}

export const ModalAIAgentDisclaimer = ({ closeModal, isStartOver }: Props) => {
  const [isCloseDisabled, setIsCloseDisabled] = useState<boolean>(true);
  const [showSpinner, SetShowSpinner] = useState<boolean>(false);
 
  return (
      // <div className={styles.modale}>
      <div className={styles.modalDialog}>
          <div className={styles.modalHeader} style={{fontStyle: 'italic'}}>
              <h2 style={{ textAlign: "left" }}>Important:</h2>
              <p className={styles.privacyText}>
                  By enabling the use of the AI Agent, the user experience will change from the original ITSM copilot to a more flexible conversational chatbot
                  interaction. <b>For best results, we recommend you write a detailed question.</b> The more specific the question, the easier it is for the AI Agent
                  to answer.
              </p>
              <p className={styles.privacyText}>
                  {" "}
                  Please note that enabling this option will disable some features from the classic version.
              </p>
          </div>

          <div className={styles.modalFooter}>
              {showSpinner ? (
                  <Spinner />
              ) : (
                  <Stack horizontal horizontalAlign="space-evenly" style={{ width: "100%" }}>
                      <StackItem>
                          <button
                              onClick={() => {
                                  closeModal(false);
                                  SetShowSpinner(!showSpinner);
                              }}
                              className={styles.btn}
                              id="btn_ingresar"
                          >
                              OK
                          </button>
                      </StackItem>
                  </Stack>
              )}
          </div>
      </div>
      // </div>
  );
};
