import React, { createContext, useContext, useState } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import OneShot from "./pages/oneshot/OneShot";
import Chat from "./pages/chat/Chat";
import { Login } from "./components/Login";
import AppStore from "./store/AppStore";
import Logout from "./pages/logout/Logout";
import { config } from "./config";
import { useIdleTimer } from 'react-idle-timer';
import Alert from "./components/Alert/Alert";


initializeIcons();



const appStore = new AppStore();
const AppContext = React.createContext<any>({});

export function useAppStore() {
  return useContext(AppContext);
}

export default function App() {
    const [userName, setUserName] = useState("");
    const addUserName=(user:string)=>{
        setUserName(user)
    }

    const onIdle = () => {
        window.location.href = config.logoutUrl
      }

      const { getRemainingTime } = useIdleTimer({
        onIdle,
        timeout: 1000 * 60 * 60 * 2,
        throttle: 500
      })
      
    return (
        <>
            {userName.length > 2 ? (
                <>
                    {/* <Login addUserName={(user:string) => addUserName(user)}/> */}
                    <HashRouter>
                        <Routes>
                            <Route path="/" element={<Layout />}>
                                <Route index element={<Chat />} />
                                <Route path="qa" element={<OneShot />} />
                                <Route path="*" element={<NoPage />} />
                            </Route>
                        </Routes>
                    </HashRouter>
                </>
            ):
            <Login addUserName={(user:string) => addUserName(user)}/>}
             <Alert />
        </>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    // <React.StrictMode>
    <AppContext.Provider value={appStore}>
         <FluentProvider theme={teamsLightTheme}>
        <App />
        </FluentProvider>
    </AppContext.Provider>
    // </React.StrictMode>
);
