import { Stack, StackItem, Text, TextField } from "@fluentui/react";
import styles from "./Modal.module.css";
import { useState } from "react";
import { Spinner } from "../Spinner";
import { useAppStore } from "../..";

interface Props {
  closeModal: (value: boolean) => void;
  isStartOver: boolean;
}

export const ModalNotification = ({ closeModal, isStartOver }: Props) => {
  const [isCloseDisabled, setIsCloseDisabled] = useState<boolean>(true);
  const [showSpinner, SetShowSpinner] = useState<boolean>(false);
  const appStore = useAppStore();
 
  return (
      // <div className={styles.modale}>
      <div className={styles.modalDialog}>
          <div className={styles.modalHeader}>
              <h2 style={{ textAlign: "center" }}>Notification</h2>
              <p className={styles.privacyText}>
                  {appStore.bannerText}
              </p>
          </div>

          <div className={styles.modalFooter}>
              {showSpinner ? (
                  <Spinner />
              ) : (
                  <Stack horizontal horizontalAlign="space-evenly" style={{ width: "100%" }}>
                      <StackItem>
                          <button
                              onClick={() => {
                                  closeModal(false);
                                  SetShowSpinner(!showSpinner);
                              }}
                              className={styles.btn}
                              id="btn_ingresar"
                          >
                              OK
                          </button>
                      </StackItem>
                  </Stack>
              )}
          </div>
      </div>
      // </div>
  );
};
